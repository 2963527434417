import { useOrganizations } from '../../core/redux/leftFilterState';
import { mapToParams, objectToMap } from '../../shared/logic/converters';
import { useQuery } from '../../shared/logic/functions';

/**
 * Redirect to given path. Also copies path parameters
 */
export const ExternalRedirect = ({ to }: {to: string}) => {
  const query = useQuery();
  const orgs = useOrganizations();
  const map = objectToMap(query);
  map.set('orgs', orgs.join(','));
  window.location.replace(to + mapToParams(map));
  return null;
};
