import { Typography } from '@mui/material';
import { FC } from 'react';

export const PopMenuHeader: FC<{
  children: string
}> = ({
  children,
}) => (
  <Typography variant="overline" component="div" align="left" sx={{ fontSize: 15 }}>
    {children}
  </Typography>
);
