import { useMemo } from 'react';
import { format, isValid } from 'date-fns';
import { useUrl } from '../../shared/hooks/params';

/** Get and switch the current date
 *
 * Switching dates only works if the page parameter is provided
 */
export const useDate = () => {
  const [{ date: urlDate }, change] = useUrl();

  const setParams = (params: Record<string, string>) => {
    change('/internutleie', params);
  };

  const date = useMemo(() => {
    if (urlDate) {
      const d = new Date(urlDate);
      if (isValid(d)) {
        return d;
      }
    }
    return new Date();
  }, [urlDate]);

  const setDate = (d: Date) => setParams({ date: format(d, 'yyyy-MM-dd') });

  return [date, setDate] as [Date, (d: Date) => void];
};
