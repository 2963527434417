import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { setOrganizations, useOrganizations } from '../../core/redux/leftFilterState';
import { useGetOrganizationsQuery } from '../../shared/redux/rental';

export const DepartmentSelector: FC = () => {
  const [selectedDepartments, setSelectedDepartments] = useState<number[]>([]);
  const { data: organizations } = useGetOrganizationsQuery();
  const reduxDepartments = useOrganizations();
  const dispatch = useDispatch();

  useEffect(() => {
    setSelectedDepartments(reduxDepartments);
  }, [reduxDepartments]);
  const updateDepartments = useCallback(() => {
    dispatch(setOrganizations(selectedDepartments));
  }, [dispatch, selectedDepartments]);

  const organizationOptions = useMemo(() => (
    organizations && [...organizations]
      .sort((a, b) => a.sortOrder - b.sortOrder)
      .map((org) => (
        <MenuItem key={org.id} value={org.id}>
          <Checkbox checked={selectedDepartments.includes(org.id)} />
          <ListItemText primary={org.name} />
        </MenuItem>
      ))
  ), [organizations, selectedDepartments]);

  const selectedOrgsString = useMemo(() => (
    selectedDepartments.map((s) => organizations?.find((o) => o.id === s))
      .sort((a, b) => a?.name.localeCompare(b?.name || '') || 0)
      .map((o) => o?.name)
      .join(', ')
  ), [organizations, selectedDepartments]);

  return (
    <FormControl
      variant="outlined"
      sx={{
        borderRadius: 1,
        backgroundColor: '#ffffff',
      }}
      fullWidth
      size="small"
    >
      {selectedDepartments.length === 0 && (
        <InputLabel shrink={false} id="project-owner">Prosjekteier</InputLabel>
      )}
      <Select
        value={selectedDepartments}
        labelId="project-owner"
        color="info"
        onChange={(e) => setSelectedDepartments(e.target.value as number[])}
        onClose={updateDepartments}
        multiple
        sx={{ height: '40px' }}
        renderValue={() => (
          <TextField
            fullWidth
            variant="standard"
            InputProps={{
              disableUnderline: true,
            }}
            sx={{
              '& .MuiInputBase-input': {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
            }}
            value={selectedOrgsString}
          />
        )}
      >
        {organizationOptions}
      </Select>
    </FormControl>
  );
};
