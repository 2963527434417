import { Box } from '@mui/material';
import { useMemo } from 'react';
import { SelectItem } from '../../../../shared/types/util/selectItem';
import { useSameUrl } from '../../../../shared/hooks/params';
import { SearchSelectDirect } from '../../../../shared/components/searchSelect';
import { unique } from '../../../../core/helpers/functions';
import { ListItemDataType } from './ListItem';

export const Filters = ({ items = [] }: { items?: ListItemDataType[]}) => {
  const allCategories = useMemo(() => unique(items.map((i) => i.category)), [items]);
  const allProjects = useMemo(() => unique(items.map((i) => i.fromProject)), [items]);
  const [params, change, remove] = useSameUrl();

  const project = useMemo((): string|null => {
    if (params.project === undefined) return null;
    if (params.project === '') return null;
    return params.project || null;
  }, [params]);

  const category = useMemo((): string|null => {
    if (params.category === undefined) return null;
    if (params.category === '') return null;
    return params.category || null;
  }, [params]);

  const changeParam = (p: string, v: string|number|undefined) => {
    if (v === null || v === undefined) remove([p]);
    else change({ [p]: v });
  };

  return (
    <Box sx={{ display: 'flex', gap: 2 }}>
      <SearchSelectDirect
        label="Prosjekt"
        value={project}
        onChange={(v) => changeParam('project', v)}
      >{allProjects?.map((p): SelectItem => ({ id: p, label: p }))}
      </SearchSelectDirect>
      <SearchSelectDirect
        label="Kategori"
        value={category}
        onChange={(v) => changeParam('category', v)}
      >{allCategories.map((c) => ({ id: c, label: c }))}
      </SearchSelectDirect>
    </Box>
  );
};
