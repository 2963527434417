import { Box } from '@mui/material';
import { FC } from 'react';
import { Tabs } from '../../shared/components/tabs';
import { InfoTab } from './partials/infoTab';
import { UnavailabilityTab } from './partials/unavailabilityTab';

export const EquipmentCard: FC<{id: number}> = ({ id }) => (
  <Tabs tabs={[
    { id: 'info', title: 'Oversikt', content: <Box minHeight={450}> <InfoTab id={id} />  </Box> },
    { id: 'unavailability', title: 'Utilgjengelighet', content: <Box minHeight={450}><UnavailabilityTab id={id} /> </Box> },
  ]}
  />
);
