import {
  isBefore,
  areIntervalsOverlapping,
  startOfDay,
  endOfDay,
} from 'date-fns';
import { EquipmentEditRules, equipmentEditRulesDefaults } from '../../shared/types/equipmentEditRules';
import { EquipmentAssignment } from '../../shared/types/equipmentAssignment';
import { Organization } from '../../shared/types/organization';
import { DateTime } from '../../shared/types/util/dateTime';

/**
 * Converts nested objects into attributes
 *
 * @param attributes Attributes to process
 * @param prefix Prefix for attributes
 * @returns Object with attribute names and values
 */
export const getAttributeSet = (attributes: any, prefix = 'data-'): Record<string, string> => {
  const keys = Object.keys(attributes);
  let attribs: Record<string, string> = {};
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    const value = attributes[key];
    if (value === null) {
      attribs[(prefix + key).toLowerCase()] = '';
    } else if (
      typeof value === 'string'
      || typeof value === 'number'
      || typeof value === 'boolean'
    ) {
      attribs[(prefix + key).toLowerCase()] = `${value}`;
    } else if (typeof value === 'object') {
      attribs = { ...attribs, ...getAttributeSet(value, `${prefix}${key}-`) };
    }
  }
  return attribs;
};

/**
 * Gets a list of attributes from a given element
 *
 * @param el Element to get from
 * @param attributes List of attributes to get
 * @param prefix Prefix used to get attributes - does not appear in data
 * @returns Object of attribute name -> attribute value
 */
export const getAttributes = (
  el: { getAttribute: (arg0: string) => any },
  attributes: string[],
  prefix = 'data-',
) => {
  const obj: Record<string, string | null> = {};
  for (let i = 0; i < attributes.length; i += 1) {
    const att = attributes[i];
    obj[att] = el.getAttribute((prefix + att).toLowerCase());
  }
  return obj;
};

export const editEquipmentRules = (
  event: EquipmentAssignment,
  isAdmin: boolean,
  isBestiller: boolean,
  userOrg: Organization | undefined,
): EquipmentEditRules => {
  const canEdit = (() => {
    if (userOrg === undefined) return false;
    return event.project.internalProduction.some((x) => x.id === userOrg.id)
      || event.project.responsible?.parentOrganizationId === userOrg.id
      || event.project.coResponsible?.parentOrganizationId === userOrg.id;
  })();
  const isFinished = event.status === 'Approved' && !!event.handInDate;

  if (event.type === 'Order') {
    return {
      ...equipmentEditRulesDefaults,
      editTime: isAdmin || canEdit,
      editEquipment: isAdmin || canEdit,
      editSubCat: isAdmin || canEdit,
      editWorksite: isAdmin || canEdit,
      editComment: isAdmin || canEdit,
      editProject: isAdmin || canEdit,
      editOrderer: isAdmin || canEdit,

      seeEquipment: isAdmin,
      seeMainCat: true,
      seeSubCat: true,
      seeComment: true,
      seeOrderComment: true,
      seeLastEditBy: true,

      seeAssignEquipmentButton: isAdmin,
      seeCancelButton: isAdmin || canEdit,
      seeSaveButton: isAdmin || canEdit,

      closeButtonText: isAdmin || canEdit ? 'Avbryt' : 'Lukk',
      cancelButtonText: isAdmin ? 'Avslå' : 'Kanseller',
    };
  }

  if (event.type === 'Assignment' && event.status === 'Created') {
    return {
      ...equipmentEditRulesDefaults,
      editTime: isAdmin || canEdit,
      editEquipment: isAdmin,
      editInternalComment: isAdmin,
      editWorksite: isAdmin || canEdit,
      editProject: isAdmin || canEdit,
      editOrderer: isAdmin || canEdit,

      seeEquipment: true,
      seeInternalComment: isAdmin,
      seeLastEditBy: true,
      seeOrderCard: isAdmin,

      seeApproveEquipmentButton: isAdmin,
      seeCancelButton: isAdmin,
      seeSaveButton: isAdmin || canEdit,

      closeButtonText: isAdmin ? 'Avbryt' : 'Lukk',
      cancelButtonText: isAdmin ? 'Avslå' : 'Kanseller',

    };
  }

  if (event.type === 'Assignment' && (event.status === 'Approved' || event.status === 'Delivered')) {
    return {
      ...equipmentEditRulesDefaults,
      editTime: isAdmin || canEdit,
      editEquipment: isAdmin,
      editInternalComment: isAdmin,
      editHandIn: isAdmin || (isBestiller && !isFinished),
      editHandOut: isAdmin,
      editWorksite: isAdmin || canEdit,
      editProject: isAdmin || canEdit,
      editOrderer: isAdmin,

      seeEquipment: true,
      seeInternalComment: isAdmin,
      seeLastEditBy: true,
      seeApprovedBy: true,
      seeOrderCard: isAdmin,

      seeSaveButton: isAdmin || canEdit,

      seeCancelButton: isAdmin,
      closeButtonText: isAdmin ? 'Avbryt' : 'Lukk',
      cancelButtonText: 'Slett',
    };
  }

  return {
    ...equipmentEditRulesDefaults,
  };
};

/**
 * Filter the array so only one object with the key exists
 * @param arr Array of objects
 * @param key Key of object that should be unique
 * @returns Unique array
 */
export const unique = <T extends object | string | number>(arr: T[] | undefined | null, key: keyof T | void) => {
  if (!arr) return [];
  const out: T[] = [];
  for (let i = 0; i < arr.length; i += 1) {
    const item = arr[i];
    if (!out.some((o) => ((typeof o === 'object') ? key && o[key] === item[key] : o === item))) {
      out.push(item);
    }
  }
  return out;
};

/**
 * Checks if ended project exists in a planned future assignment
 * @param a event assignment
 * @returns boolean
 */
export const isAssignmentProjectExpired = (a: EquipmentAssignment): boolean => (
  new Date(a?.project.endDate) < new Date()
  && (a.handInDate ? new Date(a.handInDate) : new Date(a.to)) > new Date(a?.project.endDate)
);

/** Check if an object with a from and to date overlaps today */
export const overlapsToday = (obj: {from: DateTime, to: DateTime}) => {
  if (!obj.from || !obj.to) return false;
  const start = new Date(obj.from);
  const end = new Date(obj.to);
  if (!start || !end) return false;
  if (isBefore(end, start)) return false;
  return areIntervalsOverlapping(
    { start: startOfDay(new Date()), end: endOfDay(new Date()) },
    { start, end },
  );
};

export const useOverlapsToday = () => {
  const today = { start: startOfDay(new Date()), end: endOfDay(new Date()) };
  return (obj: { from: DateTime, to: DateTime }) => {
    if (!obj.from || !obj.to) return false;
    const start = new Date(obj.from);
    const end = new Date(obj.to);

    if (!start || !end) return false;
    if (isBefore(end, start)) return false;
    return areIntervalsOverlapping(
      today,
      { start, end },
    );
  };
};
