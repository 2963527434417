import { Box, SxProps } from '@mui/material';
import { ReactNode } from 'react';

/**
 * Constrains the page to 1200px width and centers the content
 */
export const PageConstrainer = ({
  children,
  sx = {},
}: {
  children: ReactNode,
  sx?: SxProps
}) => (
  <Box sx={{
    display: 'flex',
    width: '100%',
    flex: 1,
    justifyContent: 'center',
  }}
  >
    <Box sx={{
      maxWidth: '1200px',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      ...sx,
    }}
    >
      {children}
    </Box>
  </Box>
);
