import { useMemo } from 'react';
import { useGetAllEquipmentQuery } from '../redux/rental';
import { SearchSelectFilterFunction } from '../types/util/searchSelectFilterFunction';

/** Unique separator between the extra equipment */
const uniqueSeperator = '~~@~~';

/** Provides a function to pass into a `SearchSelect` that filters equipment by
 * the search term on either the label or extra equipment IDs */
export const useEquipmentSearchFilter = <T extends number|string>(): SearchSelectFilterFunction<T> => {
  const { data: allEquipment } = useGetAllEquipmentQuery();

  const extraEquipmentRef = useMemo(() => (
    allEquipment?.map((e) => ({
      id: e.internalNumber,
      searchString: [...e.extraEquipmentIds].join(uniqueSeperator),
    })) ?? []
  ), [allEquipment]);

  return (options, { inputValue }) => options.filter((o) => {
    const searchString = inputValue.trim();
    if (o.label.includes(searchString)) return true;
    return extraEquipmentRef.some((e) => (
      e.searchString.includes(searchString) && e.id === o.id
    ));
  });
};
