import { useCallback } from 'react';
import {
  Box,
  SxProps,
  Tab,
  Tabs,
} from '@mui/material';
import { formatISO, isSameDay } from 'date-fns';
import { Header as SharedHeader } from '../../../shared/components/header';
import { useSameUrl, useUrl } from '../../../shared/hooks/params';
import { DateTimePicker } from '../../../shared/components/dateTimePicker';

export type PageName = 'handOut' | 'handIn';

export const Header = ({
  page,
}: {
  page: PageName,
}) => {
  const [, change] = useUrl();
  const onPageChange = useCallback((_: any, p: PageName) => {
    if (p === 'handIn') return change('/innlevering', { placeholder: null });
    if (p === 'handOut') return change('/utlevering', { placeholder: null });
    return null;
  }, []);
  const sx: SxProps = {
    color: '#dedede',
    fontWeight: 'bold',
    fontSize: '16px',
  };
  const [{ date: urlDate = new Date() }, update, remove] = useSameUrl();
  const date = new Date(urlDate);
  const setDate = (d: Date) => {
    if (isSameDay(d, new Date())) remove(['date']);
    else update({ date: formatISO(d) });
  };

  return (
    <SharedHeader
      middleContent={(
        <Box bgcolor="white" maxHeight={40} mt={0.5} borderRadius="4px">
          <DateTimePicker
            format="dd.MM.yyyy"
            disableTime
            size="small"
            fullWidth
            value={date}
            onChange={(d) => (d ? setDate(d) : setDate(new Date()))}
            closeOnSelect
          />
        </Box>
)}
    >
      <Tabs
        value={page}
        onChange={onPageChange}
        indicatorColor="secondary"
        textColor="secondary"
        aria-label="basic tabs example"
      >
        <Tab label="Utlevering" value="handOut" sx={sx} />
        <Tab label="Innlevering" value="handIn" sx={sx} />
      </Tabs>
    </SharedHeader>
  );
};
