import {
  APPROVED_COLOR,
  COMPLETED_COLOR,
  NOT_DELIVERED_COLOR,
  STARTED_COLOR,
  UNDER_PLANNING_COLOR,
  UNAVAILABILITY_COLOR,
  DELIVERED_COLOR,
} from '../../shared/constants';

export type AssignmentColorName = 'created'
  | 'assigned'
  | 'notDelivered'
  | 'handedOut'
  | 'completed'
  | 'approved'
  | 'unavailability'
  | 'delivered';

export type AssignmentColor = { color: string, label: string };

export const assignmentColors: Record<AssignmentColorName, AssignmentColor> = {
  created: { color: UNDER_PLANNING_COLOR, label: 'Opprettet' },
  assigned: { color: UNDER_PLANNING_COLOR, label: 'Tildelt' },
  notDelivered: { color: NOT_DELIVERED_COLOR, label: 'Ikke innlevert' },
  handedOut: { color: STARTED_COLOR, label: 'Utlevert' },
  completed: { color: COMPLETED_COLOR, label: 'Fullført' },
  approved: { color: APPROVED_COLOR, label: 'Godkjent' },
  unavailability: { color: UNAVAILABILITY_COLOR, label: 'Utilgjengelighet' },
  delivered: { color: DELIVERED_COLOR, label: 'Levert' }, // Light blue colour
};
