import { FC, useEffect } from 'react';
import {
  BrowserRouter as ReactRouter,
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { config } from './config';
import { config as workerConfig } from './shared/config/worker';
import { config as machineConfig } from './shared/config/machine';
import { urlCombine } from './shared/logic/functions';
import { AccessDeniedPage } from './pages/accessDenied';
import { ExternalRedirect } from './components/externalRedirect';
import { Unauthenticated } from './shared/components/unauthenticated';
import { NotFound } from './shared/components/NotFound';
import { RentalPage } from './pages/rentalPage';
import { HandOutPage } from './pages/handOutPage';
import { HandInPage } from './pages/handInPage';
import './router.scss';
import { useHasAppRole } from './shared/msal';

const Redirect: FC<{ to: string }> = ({ to }) => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(to);
  }, []);
  return null;
};

export const Router = () => {
  const { hasAppRole, isAuthenticated } = useHasAppRole();
  return (
    <MsalAuthenticationTemplate
      authenticationRequest={{ scopes: [config.SCOPE] }}
      interactionType={InteractionType.Redirect}
      errorComponent={Unauthenticated}
    >
      <div className="router-wrapper">
        <ReactRouter>
          <Routes>
            {isAuthenticated && hasAppRole && (
              <>
                <Route path="/ingentilgang" element={<AccessDeniedPage />} />
                <Route path="/prosjekt" element={<ExternalRedirect to={urlCombine(workerConfig.FRONTEND_URL, 'prosjekt')} />} />
                <Route path="/mannskap" element={<ExternalRedirect to={urlCombine(workerConfig.FRONTEND_URL, 'mannskap')} />} />
                <Route path="/maskin" element={<ExternalRedirect to={urlCombine(machineConfig.FRONTEND_URL, 'maskin')} />} />
                <Route path="/status" element={<ExternalRedirect to={urlCombine(workerConfig.FRONTEND_URL, 'status')} />} />
                <Route path="/internutleie" element={<RentalPage />} />
                <Route path="/utlevering" element={<HandOutPage />} />
                <Route path="/innlevering" element={<HandInPage />} />
                <Route path="/" element={<Redirect to="/internutleie" />} />
                <Route path="*" element={<NotFound />} />
              </>
            )}
            {isAuthenticated && !hasAppRole && (
              <Route path="*" element={<AccessDeniedPage />} />
            )}
          </Routes>
        </ReactRouter>
      </div>
    </MsalAuthenticationTemplate>
  );
};
