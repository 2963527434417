import {
  ReactNode,
  useCallback,
  useMemo,
} from 'react';
import { ColCellContentArg } from '@fullcalendar/resource';
import { Box, Button, Tooltip } from '@mui/material';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import { isAfter } from 'date-fns';
import { EquipmentColumn, setShowAll } from '../../../../core/redux/leftFilterState';
import { useGetAllEquipmentQuery } from '../../../../shared/redux/rental';
import { GpsChip } from '../../../../shared/components/gpsChip';
import { useDispatch, useSelector } from '../../../../shared/hooks/redux';

export const useRenderCellContent = () => {
  const { data } = useGetAllEquipmentQuery();
  const equipment = useMemo(() => data ?? [], [data]);
  const columns = useSelector((s) => s.leftFilterState.equipment.columns);
  const dispatch = useDispatch();

  const equipmentExpCert = useMemo(() => (new Map(
    equipment
      .filter((m) => m.previousCeMarkingAt)
      .map((m) => ([m.internalNumber, m.previousCeMarkingAt])),
  )), [equipment]);

  const cellContent = useCallback((c: EquipmentColumn, e: ColCellContentArg) => {
    // Everything assumes resource exists
    if (!e.resource) return '';

    // Handle show more row
    if (e.resource.id === 'show-more-equipment' && columns && c === columns[0]) {
      return (
        <Box sx={{ height: 50, display: 'relative' }}>
          <Button
            sx={{
              position: 'absolute',
              boxSizing: 'border-box',
              mr: 3,
              ml: 2,
              paddingX: 0,
              marginY: 1,
              zIndex: 5,
              width: 'calc(100% - 40px)',
              minWidth: 120,
            }}
            variant="contained"
            onClick={() => dispatch(setShowAll(true))}
          >
            Last flere
          </Button>
        </Box>
      );
    }

    const colContent = `${e.resource.extendedProps[c] ?? ''}`;

    // If ID is not set, just return column as text
    if (e.resource.id && Number.isNaN(parseInt(e.resource.id, 10))) {
      return colContent;
    }
    // If value is empty-ish (empty or whitespace), set to empty string
    const value: string = (
      colContent
      && colContent?.trim()
    ) || '';

    // Get equipment certificate expiry date
    const expCert = c === 'internalNumber' && equipmentExpCert.get(e.resource.id);

    /** Render regular equipment column - handles expired certs */
    const EquipmentContent = ({ children }: {children: ReactNode}) => (
      <Box
        display="flex"
        width="100%"
        minWidth="5px"
        height="24px"
        alignItems="center"
        className={expCert ? 'expired-column' : ''}
      >
        <span className={expCert ? 'one-line-ellipse' : ''}>
          {children}
        </span>
        {expCert && isAfter(new Date(), new Date(expCert)) && (
          <Tooltip
            title="CE godkjennelse utgått"
            followCursor
            placement="right-start"
            enterDelay={300}
            enterNextDelay={300}
            arrow
          ><EventBusyIcon
            color="error"
            sx={{ alignSelf: 'center', fontSize: '18px' }}
          />
          </Tooltip>
        )}
      </Box>
    );

    // Handle specific columns
    if (c === 'gpsMake' && value !== '') {
      return value && (
        <EquipmentContent>
          <GpsChip gps={value} sx={{ cursor: 'pointer', fontSize: '12px' }} />
        </EquipmentContent>
      );
    }

    return <EquipmentContent>{value}</EquipmentContent>;
  }, [equipment, equipmentExpCert, columns]);

  return cellContent;
};
