import CircleIcon from '@mui/icons-material/Circle';
import { Box } from '@mui/material';
import { AssignmentColor } from '../../core/types/assignmentColors';
import './style.scss';

export const StatusIcon = ({
  status: { color, label },
  isSeperated,
} : {
  status: AssignmentColor,
  isSeperated?: boolean,
}) => (
  <Box className="align-icon" sx={{ transform: isSeperated ? 'translateY(4px)' : 'inherit', mt: isSeperated ? 1 : 0 }} whiteSpace="nowrap">
    <CircleIcon sx={{ color }} />
    {label}
  </Box>
);
