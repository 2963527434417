import { Page } from '../../shared/components/page';
import { Header, List } from '../../components/HandOutInComponents';
import { PageConstrainer } from '../../components/PageConstrainer';

// TODO: Find a way to work out which items should be present, probably by
// fetching the data here and sending it in. This component probably needs to
// handle the POST too

export const HandInPage = () => (
  <Page className="hand-in-page">
    <Header page="handIn" />
    <PageConstrainer sx={{ padding: 3, gap: 2 }}>
      <List />
    </PageConstrainer>
  </Page>
);
