import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import 'react-toastify/dist/ReactToastify.min.css';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, nbNO } from '@mui/x-date-pickers';
import { MsalProvider } from '@azure/msal-react';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { Provider as ReduxProvider } from 'react-redux';
import { ThemeProvider, createTheme } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import nbLocale from 'date-fns/locale/nb';
import { store } from './core/redux';
import { Router } from './router';
import { msalInstance } from './shared/msal';
import reportWebVitals from './reportWebVitals';
import { ErrorCatcher } from './shared/components/ErrorCatcher';
import './index.scss';
import './shared/global.scss';

const persistor = persistStore(store);

const theme = createTheme({
  palette: {
    primary: {
      main: '#004B8B',
    },
    secondary: {
      main: '#ffffff',
    },
  },
}, nbNO);

const root = createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <StrictMode>
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={nbLocale}
      localeText={nbNO.components.MuiLocalizationProvider.defaultProps.localeText}
    >
      <MsalProvider instance={msalInstance}>
        <ReduxProvider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider theme={theme}>
              <ErrorCatcher>
                <Router />
                <ToastContainer />
              </ErrorCatcher>
            </ThemeProvider>
          </PersistGate>
        </ReduxProvider>
      </MsalProvider>
    </LocalizationProvider>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
