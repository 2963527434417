import {
  FormControlLabel,
  RadioGroup as MuiRadioGroup,
  Radio,
  SxProps,
} from '@mui/material';
import { useMemo } from 'react';
import { SelectItem } from '../../types/util/selectItem';

export const RadioGroup = <T extends string>({
  value = null,
  onChange = () => null,
  options = [],
  sx = {},
}: {
  value?: T|null,
  onChange?: (value: T) => void,
  options?: SelectItem<T>[],
  sx?: SxProps,
}) => {
  const opts = useMemo(() => options.map((o) => (
    <FormControlLabel key={o.id} value={o.id} control={<Radio />} label={o.label} />
  )), [options]);

  return (
    <MuiRadioGroup
      aria-labelledby="demo-controlled-radio-buttons-group"
      name="controlled-radio-buttons-group"
      value={value}
      onChange={(e) => onChange(e.target.value as T)}
      sx={sx}
    >
      {opts}
    </MuiRadioGroup>
  );
};
