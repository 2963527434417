import { Box } from '@mui/material';
import {
  endOfDay,
  formatISO,
  startOfDay,
} from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { ListItem, ListItemDataType } from './partials/ListItem';
import { Filters } from './partials/Filters';
import { CheckedStatus, ListHeader } from './partials/ListHeader';
import { useGetAllEquipmentAssignmentsQuery } from '../../../shared/redux/rental';
import { Search } from '../Search';
import { useSameUrl } from '../../../shared/hooks/params';

export const List = () => {
  const [{ date: rawDate }] = useSameUrl();
  const date = rawDate || formatISO(new Date());
  const { data: rawAssignments } = useGetAllEquipmentAssignmentsQuery({
    from: formatISO(startOfDay(new Date(date))),
    to: formatISO(endOfDay(new Date(date))),
  });
  const [searchString, setSearchString] = useState<string>('');
  const [{ project: projectFilter, category: categoryFilter }] = useSameUrl();

  const assignments = useMemo(() => {
    if (!rawAssignments) return [];
    return rawAssignments.filter((a) => {
      if (a.type === 'Assignment') return true;
      return false;
    });
  }, [rawAssignments]);

  const [current, setCurrent] = useState<ListItemDataType[]>([]);

  const data = useMemo(() => assignments.map((a): ListItemDataType => ({
    id: a.id,
    internalNumber: a.equipment.internalNumber,
    category: a.equipment.subCategoryName,
    model: a.equipment.modelName,
    fromProject: `${a.project.id} - ${a.project.projectName}`,
    checked: current.find((c) => c.id === a.id)?.checked || false,
    extraEquipment: a.extraEquipment?.map((e) => ({
      internalNumber: e.internalNumber,
      category: e.subCategoryName,
      model: e.modelName,
      checked: current.find((c) => c.id === a.id)?.extraEquipment.find((f) => f.internalNumber === e.internalNumber)?.checked || false,
    })) || [],
  })), [assignments]);

  //
  useEffect(() => {
    setCurrent(data);
  }, [data]);

  // Handle individual checkbox
  const itemChanged = (v: ListItemDataType) => {
    setCurrent(current.map((c) => (c.id === v.id ? v : c)));
  };

  const filtered = useMemo(() => {
    const ss = searchString.toLowerCase();
    const check = (s: string) => (
      s.toLowerCase().includes(ss)
    );
    return current.filter((c) => {
      // Handle project
      if (projectFilter && !c.fromProject.startsWith(projectFilter)) return false;
      // Handle category
      if (categoryFilter && c.category !== categoryFilter) return false;
      // Handle search
      if (searchString === '') return true;
      if (check(c.internalNumber)) return true;
      if (check(c.category)) return true;
      if (check(c.fromProject)) return true;
      if (c.extraEquipment.some((e) => (
        check(e.internalNumber)
        || check(e.category)
        || check(e.model)
      ))) return true;
      return false;
    });
  }, [current, searchString, projectFilter, categoryFilter]);

  /** Checks if none, some, or all checkboxes are checked in the filtered view */
  const status = useMemo((): CheckedStatus => {
    let anyChecked = false;
    let anyUnchecked = false;
    const updateChecked = (e: {checked: boolean}) => {
      if (e.checked) {
        anyChecked = true;
      } else {
        anyUnchecked = true;
      }
    };
    for (let i = 0; i < filtered.length; i += 1) {
      updateChecked(filtered[i]);
      filtered[i].extraEquipment.forEach(updateChecked);

      if (anyUnchecked && anyChecked) return 'some';
    }
    if (anyChecked) return 'all';
    return 'none';
  }, [filtered]);

  /** Set the checked status of all visible checkboxes */
  const setAllVisible = (v: boolean) => {
    setCurrent(current.map((c) => (
      !filtered.some((f) => f.id === c.id)
        ? c
        : {
          ...c,
          checked: v,
          extraEquipment: c.extraEquipment.map((e) => ({ ...e, checked: v })),
        }
    )));
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Search value={searchString} onChange={setSearchString} />
      <Box className="filter-wrapper"><Filters items={data} /></Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <ListHeader checked={status} onChange={setAllVisible} />
        {filtered.map((a) => (
          <ListItem key={a.id} item={a} onChange={itemChanged} />
        ))}
      </Box>
    </Box>
  );
};
