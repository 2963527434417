import {
  FC,
} from 'react';
import { Equipment } from '../../shared/types/equipment';
import { Modal } from '../../shared/components/modal';
import { NewEquipmentOrder } from '../../shared/components/newEquipmentOrder';
import './style.scss';

export const NewEquipmentOrderModal: FC<{
  fromDate?: Date,
  toDate?: Date,
  equipment?: Equipment,
  allEquipment?: Equipment[],
  open?: boolean,
  onClose?: () => void,
}> = ({
  fromDate = null,
  toDate = null,
  equipment = null,
  allEquipment,
  open = true,
  onClose = () => null,
}) => (
  <Modal
    open={open}
    title="Ny utstyrbestilling"
    onClose={() => onClose()}
  >
    <NewEquipmentOrder fromDate={fromDate} toDate={toDate} equipment={equipment} allEquipment={allEquipment} onClose={onClose} />
  </Modal>
);
