import { Box } from '@mui/material';
import { useEffect } from 'react';
import { MultiSearchSelect } from '../../../../shared/components/multiSearchSelect';
import { setProjectFilter } from '../../../../core/redux/viewSetting';
import { useDispatch, useSelector } from '../../../../shared/hooks/redux';
import { RootState } from '../../../../core/redux';
import { useGetAllProjectsQuery } from '../../../../shared/redux/rental';
import { useOrganizations } from '../../../../core/redux/leftFilterState';

export const ProjectFilter = () => {
  const { data: projects, isLoading } = useGetAllProjectsQuery();
  const projectFilter = useSelector((state: RootState) => state.viewSetting.projectFilter);
  const orgs = useOrganizations();
  const dispatch = useDispatch();

  const filteredProjects = projects
    ?.filter((p) => orgs.length === 0 || orgs.includes(p.responsible?.parentOrganizationId))
    .map((p) => ({ id: p.id, label: `${p.id} - ${p.projectName}` })) || [];

  useEffect(() => {
    if (isLoading) return;
    dispatch(setProjectFilter(projectFilter.filter((p) => filteredProjects.some((f) => f.id === p.id))));
  }, [projects, isLoading]);

  return (
    <Box minWidth="200px" flex={1}>
      <MultiSearchSelect
        label="Prosjekt"
        size="small"
        value={projectFilter}
        onChange={(p) => dispatch(setProjectFilter(p))}
      >
        {filteredProjects}
      </MultiSearchSelect>
    </Box>
  );
};
