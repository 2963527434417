import { isAfter } from 'date-fns';
import { EquipmentAssignment } from '../../shared/types/equipmentAssignment';
import { EquipmentColumn } from '../redux/leftFilterState';
import { assignmentColors, AssignmentColorName } from '../types/assignmentColors';

export const translateErrorCode = (endPoint: string, errorText: string, errorCode = 500) => {
  if (errorText.includes('authentication')) {
    return 'Et autoriseringsproblem har oppstått';
  }
  if (errorCode === 403) {
    return 'En autoriseringsfeil har oppstått';
  }
  if (endPoint === 'moveTask') {
    return 'Kunne ikke flytte oppgave';
  }
  if (errorText === 'User not allowed to place task on worker') {
    return 'Du har ikke rettigheter til denne handlingen';
  }
  if (errorText === 'User cannot edit this task') {
    return 'Du har ikke rettigheter til denne handlingen';
  }
  if (errorText.includes('Substitute already exists at this time')) {
    return 'Det finnes allerede vikar i ønsket periode';
  }
  return 'En feil har oppstått';
};

export const translateColumnName = (c: EquipmentColumn) => {
  if (c === 'internalNumber') return 'Int. Nr.';
  if (c === 'mainCategoryName') return 'Kategori';
  if (c === 'subCategoryName') return 'U. Kategori';
  if (c === 'modelName') return 'Model';
  if (c === 'vehicleRegistrationPlateNumber') return 'Reg.Nr';
  if (c === 'assignedDriverName') return 'Fast fører';
  if (c === 'substituteDriverName') return 'Vikar';
  if (c === 'info') return 'Info';
  if (c === 'quickCouplingAttachmentSize') return 'HK-Feste str';
  if (c === 'tiltRotatorType') return 'Rotortilt type';
  if (c === 'gpsMake') return 'GPS';
  if (c === 'position') return 'Posisjon';
  if (c === 'equipmentOwner') return 'Utleier';
  if (c === 'sorting') return 'Sortering';
  if (c === 'commentMP') return 'Kommentar MP';
  if (c === 'comment') return 'Kommentar';
  if (c === 'extraEquipment') return 'Ekstrautstyr';
  if (c === 'operatingTime') return 'Gangtid';
  return c;
};

/**
 * Get the name of the current status for an assignment
 * @param a EquipmentAssignment
 * @returns Name of status
 */
export const getOrderStatusName = (a: EquipmentAssignment): AssignmentColorName => {
  const approved = a.status === 'Approved';
  if (a.type === 'Order') return 'created';
  if (a.status === 'Delivered') return 'delivered';
  if (!approved) return 'assigned';
  if (approved && !a.handInDate && isAfter(new Date(), new Date(a.to))) return 'notDelivered';
  if (approved && !!a.handOutDate && !a.handInDate) return 'handedOut';
  if (approved && !!a.handOutDate && !!a.handInDate) return 'completed';
  if (approved) return 'approved';
  return 'assigned';
};

/**
 *
 * @param a EquipmentAssignment
 * @returns object with color and label/type
 */
export const getOrderStatus = (a: EquipmentAssignment): { color: string, label: string } => (
  assignmentColors[getOrderStatusName(a)]
);
