import { useCallback } from 'react';
import { useSelector } from '../../shared/hooks/redux';
import { Resource } from '../types/resource';

export const useSearchFilter = () => {
  const searchString = useSelector((s) => s.leftFilterState.searchString);
  const s = searchString.toLocaleLowerCase().trim();
  const check = (str: string | undefined) => (str || '').toLocaleLowerCase().includes(s);
  const filter = useCallback(
    (v: Resource) => searchString === ''
      || v.type === 'Order'
      || check(v.internalNumber)
      || check(v.vehicleRegistrationPlateNumber)
      || check(v.mainCategoryName)
      || check(v.subCategoryName)
      || check(v.modelName)
      || check(v.assignedDriverName)
      || check(v.substituteDriverName)
      || check(v.info)
      || check(v.quickCouplingAttachmentSize)
      || check(v.tiltRotatorType)
      || check(v.gpsMake)
      || check(v.sorting)
      || check(v.commentMP)
      || check(v.comment)
      || check(v.extraEquipment)
      || check(v.extraEquipmentIds?.join('  ')),
    [searchString],
  );
  return filter;
};
