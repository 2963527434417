import { Box } from '@mui/material';
import { useEffect } from 'react';
import { MultiSearchSelect } from '../../../../shared/components/multiSearchSelect';
import { useDispatch, useSelector } from '../../../../shared/hooks/redux';
import { RootState } from '../../../../core/redux';
import { assignmentColors } from '../../../../core/types/assignmentColors';
import { StatusIcon } from '../../../../components/statusIcon';
import { setColorFilter } from '../../../../core/redux/viewSetting';

const options = [
  { id: 'created/assigned', label: 'Opprettet/Tildelt', color: assignmentColors.created.color },
  { id: 'approved', ...assignmentColors.approved },
  // { id: 'handedOut', ...assignmentColors.handedOut },
  { id: 'completed', ...assignmentColors.completed },
  { id: 'notDelivered', ...assignmentColors.notDelivered },
  { id: 'delivered', ...assignmentColors.delivered },
  { id: 'unavailability', ...assignmentColors.unavailability },
  { id: 'available', label: 'Ledig i dag', color: '#f0f0f0' },
];

export const AssignmentColorFilter = () => {
  const colorFilter = useSelector((state: RootState) => state.viewSetting.colorFilter);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setColorFilter(colorFilter.filter((p) => options.some((f) => f.id === p.id))));
  }, []);

  return (
    <Box minWidth="200px" flex={1}>
      <MultiSearchSelect
        label="Fargefilter"
        size="small"
        value={colorFilter}
        renderOption={(_, option) => {
          const color = options.find((o) => o.id === option.id);
          if (!color) return null;
          return (
            <StatusIcon
              isSeperated={color.label === 'Ledig i dag'}
              status={color}
            />
          );
        }}
        renderTags={(items) => (
          <Box display="flex">
            {items.map((i) => {
              const color = options.find((o) => o.id === i.id);
              if (!color) return null;
              return (
                <StatusIcon
                  key={i.id}
                  status={{
                    color: color.color,
                    label: items.length > 1 ? '' : color.label,
                  }}
                />
              );
            })}
          </Box>
        )}
        onChange={(p) => dispatch(setColorFilter(p))}
      >
        {options}
      </MultiSearchSelect>
    </Box>
  );
};
