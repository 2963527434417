import { TextField } from '@mui/material';

export const MiniSearch = ({
  value = '',
  onChange = () => null,
}: {
  value?: string,
  onChange?: (value: string) => void
}) => (
  <TextField
    sx={{
      width: value ? '100%' : '50%',
      justifyContent: 'center',
      ':focus-within': {
        width: '100%',
        textAlign: 'left',
      },
      transition: 'width 0.3s',
    }}
    placeholder="Søk"
    type="search"
    variant="standard"
    size="small"
    value={value}
    onChange={(e) => onChange(e.target.value || '')}
  />
);
