import {
  FC,
  ReactNode,
} from 'react';
import { Box } from '@mui/material';
import { Header as SharedHeader } from '../../shared/components/header';
import { DepartmentSelector } from '../departmentSelector';
import { PageName } from '../../shared/types/util/pageName';
import { EquipmentOwnerSelector } from '../equipmentOwnerSelector';

export const Header: FC<{
  page?: PageName,
  children?: ReactNode
}> = ({
  page,
  children,
}) => (
  <SharedHeader
    page={page}
    middleContent={(
      <Box flex={1} display="flex" alignItems="center" minWidth={250} maxWidth={900} gap={4} paddingRight={2}>
        <DepartmentSelector />
        <EquipmentOwnerSelector />
      </Box>
    )}
  >
    {children}
  </SharedHeader>
);
