import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { setEquipmentOwners, useEquipmentOwners } from '../../core/redux/leftFilterState';
import { useGetEquipmentOwnersQuery } from '../../shared/redux/rental';
import { EquipmentOwner } from '../../shared/types/equipmentOwner';

export const EquipmentOwnerSelector: FC = () => {
  const [selectedOwners, setSelectedOwners] = useState<number[]>([]);
  const { data: owners } = useGetEquipmentOwnersQuery();
  const reduxOwners = useEquipmentOwners();
  const dispatch = useDispatch();

  const stringify = (o: EquipmentOwner) => `${o.name} (${o.responsible})`;

  useEffect(() => {
    setSelectedOwners(reduxOwners);
  }, [reduxOwners]);
  const updateOwners = useCallback(() => {
    dispatch(setEquipmentOwners(selectedOwners));
  }, [dispatch, selectedOwners]);

  const ownerOptions = useMemo(() => (
    owners && [...owners]
      .filter((o) => o.id !== 0)
      .map((o) => (
        <MenuItem key={o.id} value={o.id}>
          <Checkbox checked={selectedOwners.includes(o.id)} />
          <ListItemText primary={stringify(o)} />
        </MenuItem>
      ))
  ), [owners, selectedOwners]);

  const selectedOwnersString = useMemo(() => (
    selectedOwners.map((s) => owners?.find((o) => o.id === s))
      .sort((a, b) => a?.name.localeCompare(b?.name || '') || 0)
      .map((o) => (o ? stringify(o) : '...'))
      .join(', ')
  ), [owners, selectedOwners]);

  return (
    <FormControl
      variant="outlined"
      sx={{
        borderRadius: 1,
        backgroundColor: '#ffffff',
      }}
      fullWidth
      size="small"
    >
      {selectedOwners.length === 0 && (
      <InputLabel shrink={false} id="equipment-owner">Utleier</InputLabel>
      )}
      <Select
        value={selectedOwners}
        labelId="equipment-owner"
        color="info"
        onChange={(e) => setSelectedOwners(e.target.value as number[])}
        onClose={updateOwners}
        multiple
        sx={{ height: '40px' }}
        renderValue={() => (
          <TextField
            fullWidth
            variant="standard"
            InputProps={{
              disableUnderline: true,
            }}
            sx={{
              '& .MuiInputBase-input': {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
            }}
            value={selectedOwnersString}
          />
        )}
      >
        {ownerOptions}
      </Select>
    </FormControl>
  );
};
