import { Chip, SxProps, Theme } from '@mui/material';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';

export const GpsChip = ({
  gps,
  sx = {},
}: {
  gps: string,
  sx?: SxProps<Theme>
}) => {
  const colorMap = new Map([
    ['Topcon', '#5b5346'],
    ['Easydig', '#182029'],
    ['Leica', '#b8342f'],
    ['Trimble', '#dbac01'],
    ['Makin3D', '#1c8a50'],
  ]);

  return (
    <Chip
      variant="filled"
      avatar={<GpsFixedIcon sx={{ fill: 'white' }} />}
      sx={{
        backgroundColor: colorMap.get(gps) || 'gray',
        color: 'white',
        ...sx,
      }}
      label={gps.toLocaleUpperCase()}
      size="small"
    />
  );
};
