import { FC } from 'react';
import { Box, Skeleton } from '@mui/material';
import { UnavailabilityView } from '../../unavailabilityView/index';
import { useGetEquipmentQuery } from '../../../shared/redux/rental';

export const UnavailabilityTab: FC<{id: number}> = ({ id }) => {
  const { data: equipment, isLoading } = useGetEquipmentQuery(id);
  if (!equipment || isLoading) {
    return (
      <div className="project-details">
        <Box display="flex" alignItems="flex-start">
          <Box flex={1} display="flex" flexDirection="column" gap={2}>

            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={300} />

          </Box>
          <Box flex={1} display="flex" flexDirection="column" gap={2}>
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
          </Box>

          <Box flex={1} display="flex" flexDirection="column" gap={2}>
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
          </Box>
        </Box>
      </div>
    );
  }

  return (
    <div>
      <UnavailabilityView
        id={equipment.internalNumber}
        currentDate={new Date()}
      />
    </div>
  );
};
