import { useEffect, useState } from 'react';

/**
 * Call a given function after a set timeout. Timeout resets every time value
 * updates. The function will not be called if the value is undefined.
 *
 * @param callback Function to call after debounce
 * @param timeout Debounce time
 * @returns Function to update value
 */
export const useDebounce = <T extends any>(callback: (v: T) => void, timeout: number) => {
  const [value, setValue] = useState<T|undefined>();

  useEffect(() => {
    const ref = setTimeout(() => {
      if (value === undefined) return;
      callback(value);
    }, timeout);
    return () => clearTimeout(ref);
  }, [value]);

  return setValue;
};

/**
 * Call a given function after a set timeout. Timeout resets every time any
 * value updates.
 * This version handles sets of key and value pairs, and calls the function
 * for each.
 *
 * @param callback Function to call after debounce
 * @param timeout Debounce time
 * @returns Function to update value
 */
export const useDebounceArray = <K extends string, T extends any>(
  callback: (k: K, v: T) => void,
  timeout: number,
) => {
  const [value, setValue] = useState<Partial<Record<K, T>>>({});

  useEffect(() => {
    const ref = setTimeout(() => {
      const keys = Object.keys(value) as K[];
      if (keys.length <= 0) return;
      keys.forEach((k) => {
        callback(k, value[k] as T);
      });
      setValue({});
    }, timeout);
    return () => clearTimeout(ref);
  }, [value]);

  return (k: K, v: T) => {
    const old = value[k];
    if (old && old === v) return;
    setValue((o) => ({ ...o, [k]: v }));
  };
};
