import { FC } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { EditUnavailability } from '../editUnavailability';
import { UnavailabilityView } from '../unavailabilityView';
import { Guid } from '../../shared/types/guid';

export const UnavailabilityModal: FC<{
  internalNumber: string;
  currentDate: Date;
  onClose: () => void;
  title: string,
  isEdit: boolean,
  unavailabilityId?: Guid,
}> = ({
  internalNumber,
  currentDate,
  onClose,
  title,
  isEdit,
  unavailabilityId = '',
}) => (
  <Dialog
    open
    onClose={onClose}
    maxWidth="md"
    fullWidth
  >
    <div className="align-status">
      <div className="left">
        <DialogTitle>{title}</DialogTitle>
      </div>
      <IconButton sx={{ marginRight: '15px' }} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </div>
    <DialogContent>
      {isEdit ? (
        <EditUnavailability
          equipmentId={internalNumber}
          id={unavailabilityId}
          onAction={onClose}
        />
      ) : (
        <UnavailabilityView
          id={internalNumber}
          currentDate={currentDate}
          onClose={onClose}
        />
      )}
    </DialogContent>
  </Dialog>
);
