import { IconButton, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const Search = ({
  value = '',
  onChange = () => null,
}: {
    value?: string,
    onChange?: (v: string) => void,
}) => (
  <TextField
    placeholder="Søk"
    value={value}
    onChange={(e) => onChange(e.target.value)}
    InputProps={{
      endAdornment: value === '' ? undefined : (
        <IconButton onClick={() => onChange('')}>
          <CloseIcon />
        </IconButton>
      ),
    }}
  />
);
