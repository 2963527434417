import { Box } from '@mui/material';
import { useMemo } from 'react';
import { Header } from '../../components/header';
import { Page } from '../../shared/components/page';
import { Planner } from './planner';
import {
  useGetAllEquipmentQuery,
  useGetAllEquipmentAssignmentsQuery,
} from '../../shared/redux/rental';
import './style.scss';
import {
  useOrganizations,
  useEquipmentOwners,
  setSortingFilter,
  setCouplingSizeFilter,
  setTiltRotatorFilter,
  setManagerFilter,
  setOrderersFilter,
} from '../../core/redux/leftFilterState';
import { useTimeFrameStrings } from '../../core/hooks/useTimeframe';
import { MultiSearchSelect } from '../../shared/components/multiSearchSelect';
import { useDispatch, useSelector } from '../../shared/hooks/redux';
import { arrayEq } from '../../shared/logic/arrayEq';
import { labelSort } from '../../shared/logic/functions';

export const RentalPage = () => {
  const owners = useEquipmentOwners();
  const timeFrame = useTimeFrameStrings();
  const organizations = useOrganizations();
  const leftMainCat = useSelector((s) => s.leftFilterState.equipment.mainCategories ?? [], arrayEq);
  const leftSubCat = useSelector((s) => s.leftFilterState.equipment.subCategories ?? [], arrayEq);
  const leftSortFilter = useSelector((s) => s.leftFilterState.equipment.sortingFilter ?? [], arrayEq);
  const leftCouplingFilter = useSelector((s) => s.leftFilterState.equipment.couplingSizeFilter ?? [], arrayEq);
  const leftRotatorFilter = useSelector((s) => s.leftFilterState.equipment.tiltRotatorFilter ?? [], arrayEq);
  const leftManagerFilter = useSelector((s) => s.leftFilterState.managers ?? [], arrayEq);
  const leftOrdererFilter = useSelector((s) => s.leftFilterState.orderers ?? [], arrayEq);
  const showAllOrders = useSelector((s) => s.viewSetting.showAllOrders);

  const dispatch = useDispatch();

  const { data, isLoading: equipmentLoading } = useGetAllEquipmentQuery({ owners });
  const equipment = useMemo(() => data ?? [], [data]);

  const {
    data: assignments = [],
    isLoading: assignmentsLoading,
    isFetching: assignmentsFetching,
    refetch: refetchAssignments,
  } = useGetAllEquipmentAssignmentsQuery(
    {
      organizations, equipmentOwners: owners, ...timeFrame, filterOrders: !showAllOrders,
    },
    { pollingInterval: 30000 },
  );

  const combinedLoading = equipmentLoading || assignmentsLoading || assignmentsFetching;

  const plannerAssignments = useMemo(
    () => assignments.filter((a) => {
      if (a.type === 'Order') {
        return (
          leftMainCat.length === 0 || leftMainCat.some((mc) => a.category === mc.label)
        ) && (
          leftSubCat.length === 0 || leftSubCat.some((mc) => a.subCategory === mc.label)
        );
      }
      return true;
    })
      .filter((a) => leftManagerFilter.length === 0
      || leftManagerFilter.some((m) => m.id === a.project?.operationsManager?.employeeNumber))
      .filter((m) => leftOrdererFilter.length === 0
      || leftOrdererFilter.some((o) => m.orderer?.fullName === o.id)),
    [assignments, leftMainCat, leftSubCat, leftManagerFilter, leftOrdererFilter],
  );

  const plannerEquipment = useMemo(() => {
    if (!equipment) return [];
    const machine = equipment
      .filter((m) => leftMainCat.length === 0 || leftMainCat.some((mc) => m.mainCategoryName === mc.label))
      .filter((m) => leftSubCat.length === 0 || leftSubCat.some((sc) => m.subCategoryName === sc.label))
      .filter((m) => leftSortFilter.length === 0 || leftSortFilter.some((s) => (m.sorting === '' && s.label === 'Ingen sortering' ? true : m.sorting === s.label)))
      .filter((m) => leftCouplingFilter.length === 0 || leftCouplingFilter.some((c) => m.quickCouplingAttachmentSize === c.label))
      .filter((m) => leftRotatorFilter.length === 0 || leftRotatorFilter.some((r) => m.tiltRotatorType === r.id))
      // When orderer filter is active, only equipment with assignments should be shown
      .filter((m) => leftOrdererFilter.length === 0 || plannerAssignments.some((a) => a.equipment.internalNumber === m.internalNumber))
      .map((m) => ({ ...m }));
    return [...machine];
  }, [
    equipment,
    leftMainCat,
    leftSubCat,
    leftSortFilter,
    leftCouplingFilter,
    leftRotatorFilter,
    leftOrdererFilter,
    plannerAssignments,
  ]);

  const sortingFilter = useMemo(() => {
    const items = new Set<string>();
    equipment
      ?.forEach((e) => {
        if (e.sorting) {
          items.add(e.sorting);
        }
      });
    const ret = Array.from(items, (i) => ({ id: i, label: i })).sort(labelSort);
    ret.unshift({ id: 'Ingen sortering', label: 'Ingen sortering' });
    return ret;
  }, [equipment, leftSortFilter]);

  const couplingSizeFilter = useMemo(() => {
    const items = new Set<string>();
    equipment
      ?.forEach((e) => {
        if (e.quickCouplingAttachmentSize) {
          items.add(e.quickCouplingAttachmentSize);
        }
      });
    return Array.from(items, (i) => ({ id: i, label: i })).sort(labelSort);
  }, [equipment, leftCouplingFilter]);

  const tiltRotatorFilter = useMemo(() => {
    const items = new Set<string>();
    equipment
      ?.forEach((e) => {
        if (e.tiltRotatorType) {
          items.add(e.tiltRotatorType);
        }
      });
    return Array.from(items, (i) => ({ id: i, label: i.padStart(5) })).sort(labelSort);
  }, [equipment, leftRotatorFilter]);

  const projectManagers = useMemo(() => {
    const names = new Map<number, string>();
    assignments?.forEach((a) => {
      if (a.project.operationsManager) {
        names.set(
          a.project.operationsManager.employeeNumber,
          a.project.operationsManager.fullName,
        );
      }
    });
    return Array.from(names, ([id, label]) => ({ id, label })).sort(labelSort);
  }, [assignments]);

  const orderersList = useMemo(() => {
    const names = new Set<string>();
    assignments?.forEach((a) => {
      if (a.orderer) {
        names.add(a.orderer.fullName);
      }
    });
    return Array.from(names, (name) => ({ id: name, label: name })).sort(labelSort);
  }, [assignments]);

  return (
    <Page className="transportplanlegger-page">
      <Header page="rental" />
      <Planner
        equipment={plannerEquipment}
        assignments={plannerAssignments}
        loading={combinedLoading}
        refetchAssignments={refetchAssignments}
        onLeftSelect={() => null}
        leftFilters={(
          <Box display="flex" flexDirection="column" gap={2}>
            <span>
              <MultiSearchSelect
                label="Driftsleder"
                size="small"
                value={leftManagerFilter}
                onChange={(v) => dispatch(setManagerFilter(v))}
              >
                {projectManagers}
              </MultiSearchSelect>
            </span>
            <span>
              <MultiSearchSelect
                label="Bestiller"
                size="small"
                value={leftOrdererFilter}
                onChange={(v) => dispatch(setOrderersFilter(v))}
              >
                {orderersList}
              </MultiSearchSelect>
            </span>
            <span>
              <MultiSearchSelect
                label="Sortering"
                size="small"
                value={leftSortFilter}
                onChange={(v) => dispatch(setSortingFilter(v))}
              >
                {sortingFilter}
              </MultiSearchSelect>
            </span>
            <span>
              <MultiSearchSelect
                label="HK-Feste str"
                size="small"
                value={leftCouplingFilter}
                onChange={(v) => dispatch(setCouplingSizeFilter(v))}
              >
                {couplingSizeFilter}
              </MultiSearchSelect>
            </span>
            <span>
              <MultiSearchSelect
                label="Rotortilt type"
                size="small"
                value={leftRotatorFilter}
                onChange={(v) => dispatch(setTiltRotatorFilter(v))}
              >
                {tiltRotatorFilter}
              </MultiSearchSelect>
            </span>
          </Box>
        )}
      />
    </Page>
  );
};
