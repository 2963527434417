/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { SelectItem } from '../../shared/types/util/selectItem';

const stateName = 'viewSetting';

interface InitialState {
  showWeekend: boolean;
  showAllOrders: boolean;
  showOnlyAssignments: boolean;
  showOnlyAssignmentsWithExpiredProject: boolean;
  showOnlyEventOverlap: boolean;
  projectFilter: SelectItem[];
  colorFilter: SelectItem[];
}

const initialState: InitialState = {
  showWeekend: false,
  showAllOrders: false,
  showOnlyAssignments: false,
  showOnlyAssignmentsWithExpiredProject: false,
  showOnlyEventOverlap: false,
  projectFilter: [],
  colorFilter: [],
};

const viewSettingSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    setWeekend(state, action: PayloadAction<boolean>) {
      state.showWeekend = action.payload;
    },
    setShowAllOrders(state, action: PayloadAction<boolean>) {
      state.showAllOrders = action.payload;
    },
    setOnlyAssignment(state, action: PayloadAction<boolean>) {
      state.showOnlyAssignments = action.payload;
    },
    setOnlyAssignmentsWithExpiredProject(state, action: PayloadAction<boolean>) {
      state.showOnlyAssignmentsWithExpiredProject = action.payload;
    },
    setOnlyEventOverlap(state, action: PayloadAction<boolean>) {
      state.showOnlyEventOverlap = action.payload;
    },
    setProjectFilter(state, action: PayloadAction<SelectItem[]>) {
      state.projectFilter = action.payload;
    },
    setColorFilter(state, action: PayloadAction<SelectItem[]>) {
      state.colorFilter = action.payload;
    },
    reset(state) {
      Object.assign(state, initialState);
    },
  },
});

export const {
  setWeekend,
  setShowAllOrders,
  setOnlyAssignment,
  setOnlyAssignmentsWithExpiredProject,
  setOnlyEventOverlap,
  setProjectFilter,
  setColorFilter,
  reset,
} = viewSettingSlice.actions;

export default persistReducer({ key: stateName, storage }, viewSettingSlice.reducer);
