/* eslint-disable no-param-reassign */
import { useMemo } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { SelectItem } from '../../shared/types/util/selectItem';
import { useSelector } from '../../shared/hooks/redux';

const stateName = 'leftFilterState';

const equipmentColumns = [
  'mainCategoryName',
  'subCategoryName',
  'internalNumber',
  'modelName',
  'vehicleRegistrationPlateNumber',
  'assignedDriverName',
  'substituteDriverName',
  'info',
  'quickCouplingAttachmentSize',
  'tiltRotatorType',
  'operatingTime',
  'gpsMake',
  'position',
  'equipmentOwner',
  'sorting',
  'commentMP',
  'comment',
  'extraEquipment',
] as const;
export type EquipmentColumn = typeof equipmentColumns[number];

interface InitialState {
  equipment: {
    columns: Array<EquipmentColumn>,
    mainCategories: SelectItem[],
    subCategories: SelectItem[],
    sortingFilter: SelectItem[],
    couplingSizeFilter: SelectItem[],
    tiltRotatorFilter: SelectItem[],
    showAll: boolean,
  },
  equipmentColumnWidths: Partial<Record<EquipmentColumn, number>>,
  sidebarWidth: number,
  managers: SelectItem[]
  orderers: SelectItem[]
  defaultSet: boolean,
  equipmentOwners: Array<number>,
  organizations: Array<number>,
  searchString: string,
  /** Only show equipment without assignments */
}

const initialState: InitialState = {
  equipment: {
    columns: ['subCategoryName', 'internalNumber', 'modelName'],
    mainCategories: [],
    subCategories: [],
    sortingFilter: [],
    couplingSizeFilter: [],
    tiltRotatorFilter: [],
    showAll: false,
  },
  equipmentColumnWidths: {},
  sidebarWidth: 500,
  managers: [],
  orderers: [],
  defaultSet: false,
  equipmentOwners: [],
  organizations: [],
  searchString: '',
};

const leftFilterState = createSlice({
  name: stateName,
  initialState,
  reducers: {
    setColumns(state, { payload: { equipment } }: PayloadAction<{equipment: EquipmentColumn[]}>) {
      state.equipment.columns = equipment.filter((c) => equipmentColumns.includes(c));
    },
    setMainCategories(state, { payload: mainCategories }: PayloadAction<SelectItem[]>) {
      state.equipment.mainCategories = mainCategories;
      state.equipment.subCategories = [];
    },
    setSubCategories(state, { payload: subCategories }: PayloadAction<SelectItem[]>) {
      state.equipment.subCategories = subCategories;
    },
    setSortingFilter(state, { payload: sortingFilter }: PayloadAction<SelectItem[]>) {
      state.equipment.sortingFilter = sortingFilter;
    },
    setCouplingSizeFilter(state, { payload: couplingSizeFilter }: PayloadAction<SelectItem[]>) {
      state.equipment.couplingSizeFilter = couplingSizeFilter;
    },
    setTiltRotatorFilter(state, { payload: tiltRotatorFilter }: PayloadAction<SelectItem[]>) {
      state.equipment.tiltRotatorFilter = tiltRotatorFilter;
    },
    setManagerFilter(state, { payload: managers }: PayloadAction<SelectItem[]>) {
      state.managers = managers;
    },
    setOrderersFilter(state, { payload: orderers }: PayloadAction<SelectItem[]>) {
      state.orderers = orderers;
    },
    setEquipmentOwners(state, { payload: equipmentOwners }: PayloadAction<number[]>) {
      state.equipmentOwners = equipmentOwners;
    },
    setOrganizations(state, { payload: organizations }: PayloadAction<number[]>) {
      state.organizations = organizations;
    },
    setSearchString(state, { payload: searchString }: PayloadAction<string>) {
      state.searchString = searchString;
    },
    setShowAll(state, { payload: showAll }: PayloadAction<boolean>) {
      state.equipment.showAll = showAll;
    },
    setColumnWidth(state, { payload: item }: PayloadAction<{key: EquipmentColumn, value: number}>) {
      state.equipmentColumnWidths[item.key] = item.value;
    },
    setSidebarWidth(state, { payload: width }: PayloadAction<number>) {
      state.sidebarWidth = width;
    },
    reset(state) {
      Object.assign(state, initialState);
    },
  },
});

const sortOrder: EquipmentColumn[] = [
  'mainCategoryName',
  'subCategoryName',
  'internalNumber',
  'modelName',
  'vehicleRegistrationPlateNumber',
  'assignedDriverName',
  'substituteDriverName',
  'info',
  'quickCouplingAttachmentSize',
  'tiltRotatorType',
  'operatingTime',
  'gpsMake',
  'position',
  'equipmentOwner',
  'sorting',
  'commentMP',
  'comment',
  'extraEquipment',
];

export const useColumns = () => {
  const state = useSelector((s) => s.leftFilterState.equipment.columns);
  const sorted = useMemo(() => {
    const cols = [...state].filter((c) => equipmentColumns.includes(c));
    cols.sort((a, b) => sortOrder.indexOf(a) - sortOrder.indexOf(b));
    return cols;
  }, [state]);
  return sorted;
};

export const useOrganizations = () => {
  const organizations = useSelector((s) => s.leftFilterState.organizations);
  return organizations;
};

export const useEquipmentOwners = () => {
  const equipmentOwners = useSelector((s) => s.leftFilterState.equipmentOwners);
  return equipmentOwners;
};

export const useColumnNames = () => equipmentColumns;

export const {
  setColumns,
  setMainCategories,
  setSubCategories,
  setSortingFilter,
  setCouplingSizeFilter,
  setTiltRotatorFilter,
  setManagerFilter,
  setOrderersFilter,
  setEquipmentOwners,
  setOrganizations,
  setSearchString,
  setShowAll,
  setColumnWidth,
  setSidebarWidth,
  reset,
} = leftFilterState.actions;

export default persistReducer({ key: stateName, storage }, leftFilterState.reducer);
