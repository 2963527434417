import { useMemo } from 'react';
import { useUrl } from '../../shared/hooks/params';
import {
  CalendarView,
  calendarViews,
  view as viewType,
} from '../types/util/calendarView';

/** Get and switch the current view
 *
 * Switching dates only works if the page parameter is provided
 */
export const useView = () => {
  const [{ view: rawView }, change] = useUrl();

  const setParams = (params: Record<string, string>) => {
    change('/internutleie', params);
  };

  const view = useMemo(() => {
    if (!rawView) return viewType.month;
    if (calendarViews.includes(rawView as CalendarView)) {
      return rawView as CalendarView;
    }
    return viewType.month;
  }, [rawView]);

  const setView = (v: CalendarView) => {
    setParams({ view: v });
  };

  return [view, setView] as [CalendarView, (v: CalendarView) => void];
};
