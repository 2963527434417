import {
  FC,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import FullC from '@fullcalendar/react'; // must go before plugins
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin, { EventReceiveArg, EventResizeDoneArg } from '@fullcalendar/interaction';
import nbLocale from '@fullcalendar/core/locales/nb';
import {
  Box,
  CircularProgress,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { ColSpec } from '@fullcalendar/resource';
import {
  DateSelectArg,
  EventApi,
  EventClickArg,
  EventContentArg,
  EventDropArg,
  EventInput,
} from '@fullcalendar/core';
import {
  addHours,
  addYears,
  areIntervalsOverlapping,
  endOfYear,
  formatISO,
  isAfter,
  startOfDay,
  startOfYear,
  subYears,
} from 'date-fns';
import { toast } from 'react-toastify';
import { TimelineHeader } from './partials/timelineHeader';
import { useView } from '../../../core/hooks/useView';
import { useDate } from '../../../core/hooks/useDate';
import { useDraggable } from '../hooks/useDraggable';
import {
  EquipmentColumn,
  setColumnWidth,
  setShowAll,
  setSidebarWidth,
  useColumns,
  useEquipmentOwners,
  useOrganizations,
} from '../../../core/redux/leftFilterState';
import { useDefaultUrlParams } from '../hooks/useDefaultUrlParams';
import { useTimeframeStartEnd, useTimeFrameStrings } from '../../../core/hooks/useTimeframe';
import {
  useGetAllEquipmentQuery,
  useGetAllProjectsQuery,
  useGetAllUnavailabilitiesQuery,
  useGetAllWorkersQuery,
  useGetCalendarQuery,
  useGetEquipmentOwnersQuery,
  useGetMyOrganizationQuery,
  usePostCopyAssignmentMutation,
  usePutApproveOrderBodyMutation,
  usePutEquipmentUnavailabilityMutation,
  usePutMoveEquipmentAssignmentMutation,
  usePutMoveEquipmentOrderMutation,
} from '../../../shared/redux/rental';
import { useHasRoles } from '../../../core/helpers/useHasRoles';
import {
  getOrderStatus,
  translateColumnName,
  getOrderStatusName,
} from '../../../core/helpers/translate';
import { LeftHeader } from './partials/leftHeader';
import { Equipment } from '../../../shared/types/equipment';
import { useRenderCellContent } from './hooks/useRenderCellContent';
import { useDispatch, useSelector } from '../../../shared/hooks/redux';
import { useSearchFilter } from '../../../core/hooks/useSearchFilter';
import { Resource } from '../../../core/types/resource';
import { EquipmentAssignment } from '../../../shared/types/equipmentAssignment';
import {
  editEquipmentRules,
  isAssignmentProjectExpired,
  useOverlapsToday,
} from '../../../core/helpers/functions';
import { Modal } from '../../../shared/components/modal';
import { EquipmentOrderModal } from '../../../components/equipmentOrderModal';
import { Displayable } from '../../../shared/types/util/displayable';
import { NewEquipmentOrderModal } from '../../../components/newEquipmentOrderModal';
import { MoveEquipmentOrder } from '../../../shared/types/api/moveEquipmentOrder';
import { ApproveEquipmentOrder } from '../../../shared/types/api/approveEquipmentOrder';
import { MoveEquipmentAssignment } from '../../../shared/types/api/moveEquipmentAssignment';
import { EquipmentUnavailability } from '../../../shared/types/equipmentUnavailability';
import { EditUnavailability } from '../../../components/editUnavailability';
import { eventifyUnavailability } from '../../../core/helpers/eventify';
import { NewUnavailability } from '../../../shared/types/api/newUnavailability';
import { EquipmentCard } from '../../../components/equipmentCard';
import { ensureAfter, format } from '../../../shared/logic/dates';
import { HoverEvent } from '../../../components/hoverEvent';
import { EventCommentIcon } from '../../../shared/components/eventCommentIcon';
import { LastEditInfo } from '../../../shared/components/lastEditInfo';
import { allowedEventMovement } from '../../../shared/logic/functions';
import { EventProjectExpiredIcon } from '../../../shared/components/eventProjectExpiredIcon';
import { getClassValue } from '../../../core/helpers/classnames';
import { useDebounce, useDebounceArray } from '../../../core/hooks/useDebouce';

/**
 * Number of items to display before "Last flere" button is pressed.
 *
 * This feature reduces load times and increases performance.
 */
const UNEXPANDED_COUNT = 200;

export const Planner: FC<{
  equipment: Equipment[],
  assignments: EquipmentAssignment[],
  loading: boolean,
  leftFilters?: ReactNode,
  onLeftSelect?: (id: number, title: string) => void,
  refetchAssignments: () => void
}> = ({
  equipment,
  assignments,
  loading,
  leftFilters,
  onLeftSelect = () => null,
  refetchAssignments,
}) => {
  useDefaultUrlParams();
  const [date, setDate] = useDate();
  const [view, setView] = useView();
  const {
    showWeekend = false,
    projectFilter,
    colorFilter,
    showAllOrders = false,
    showOnlyAssignmentsWithExpiredProject = false,
    showOnlyEventOverlap = false,
  } = useSelector((s) => s.viewSetting);
  const showAvailableToday = useMemo(() => colorFilter.some((c) => c.id === 'available'), [colorFilter]);
  const filteredColorFilter = useMemo(() => colorFilter.filter((c) => c.id !== 'available'), [colorFilter]);

  const organizations = useOrganizations();

  /** If true, only resources (equipment) with events should be shown */
  const onlyResourcesWithEvents = useMemo(() => (
    filteredColorFilter.length > 0
    || projectFilter.length > 0
    || organizations.length > 0
  ), [filteredColorFilter, projectFilter, organizations]);

  const {
    showAll: showAllEquipment,
    mainCategories,
    subCategories,
  } = useSelector((s) => s.leftFilterState.equipment);
  const {
    sidebarWidth,
    equipmentColumnWidths: columnWidths,
  } = useSelector((s) => s.leftFilterState);
  const dispatch = useDispatch();
  const setShowAllEquipment = (v: boolean) => dispatch(setShowAll(v));
  useEffect(() => {
    setShowAllEquipment(false);
  }, []);
  const timeframe = useTimeframeStartEnd();
  const timeStrings = useTimeFrameStrings();
  const dataColumns = useColumns();
  const searchFilter = useSearchFilter();
  const [firstRun, setFirstRun] = useState<boolean>(true);
  const [activeEvent, setActiveEvent] = useState<EquipmentAssignment | null>(null);
  const [activeEquipment, setActiveEquipment] = useState<{id: number, title: string} | null>(null);
  const [selectedUnavailability, setSelectedUnavailability] = useState<EquipmentUnavailability | null>(null);
  const [contextMenu, setContextMenu] = useState<{
    id: string,
    mouseX: number,
    mouseY: number,
  } | null>(null);

  const isAdmin = useHasRoles('internutleie-koordinator');
  const isBestiller = useHasRoles('bestiller') && !isAdmin;
  const { data: userOrg } = useGetMyOrganizationQuery();

  const { data: projects } = useGetAllProjectsQuery();
  const { data: owners } = useGetEquipmentOwnersQuery();
  const { data: allEquipment } = useGetAllEquipmentQuery();
  const { data: unavailabilities } = useGetAllUnavailabilitiesQuery({
    start: timeStrings.from,
    end: timeStrings.to,
  });
  const { data: workers } = useGetAllWorkersQuery();

  const [approveOrder] = usePutApproveOrderBodyMutation();
  const [changeOrder] = usePutMoveEquipmentOrderMutation();
  const [changeAssignment] = usePutMoveEquipmentAssignmentMutation();
  const [postAssignmentCopy] = usePostCopyAssignmentMutation();
  const [updateUnavailability] = usePutEquipmentUnavailabilityMutation();

  const { data: nonWorkingDays } = useGetCalendarQuery({
    from: formatISO(startOfYear(date)),
    to: formatISO(endOfYear(date)),
  });

  const reduxOwners = useEquipmentOwners();

  const calRef: RefObject<FullC> = useRef<FullC>(null);
  const calApi = useMemo(() => (calRef.current ? calRef.current.getApi() : null), [calRef.current]);

  const [, setIsDragging] = useState<EventApi|null>(null);

  useEffect(() => {
    if (calApi) calApi.changeView(view);
  }, [calApi, view]);

  useEffect(() => {
    if (calApi) calApi.gotoDate(date);
  }, [calApi, date]);

  useEffect(() => {
    const els = Object.values(document.getElementsByClassName('fc-scroller fc-scroller-liquid-absolute'));
    if (els.length <= 0) return;
    setTimeout(() => els[0].scrollTo(0, 0), 50);
  }, [mainCategories, subCategories, colorFilter, projectFilter, organizations, reduxOwners]);

  useEffect(() => setFirstRun(false), []);

  useDraggable();

  const holidays = useMemo(() => {
    if (!nonWorkingDays) return [];
    return nonWorkingDays.map(
      (n): EventInput => ({
        id: n.date,
        start: addHours(startOfDay(new Date(n.date)), 7),
        end: addHours(startOfDay(new Date(n.date)), 15),
        display: 'background',
        color: 'rgb(215, 215, 215)',
      }),
    );
  }, [nonWorkingDays]);

  const modalProjects = useMemo(() => (
    projects?.map((p) => ({ id: p.id, label: p.projectName, endDate: p.endDate })) as Displayable[]
  ), [projects]);

  /** Assignments that have been updated, but where the update has not come back from the server */
  const [updatedAssignments, setUpdatedAssignments] = useState<EquipmentAssignment[]>(assignments);

  const assignmentResources = useMemo(() => assignments
    .filter((a) => a.type === 'Order')
    .map((a): Resource => ({
      id: a.subCategory,
      type: a.type,
      title: a.subCategory,
      mainCategoryName: a.category,
      subCategoryName: a.subCategory,
      sortOrder: `0${a.category}${a.subCategory}`
        .trim()
        .toLocaleLowerCase('nb'),
    })) || [], [assignments]);

  const FCResourcesUnfiltered = useMemo(() => {
    const overlapsTodayPred = useOverlapsToday();

    if (!equipment) return [];
    const equipmentResources = equipment
      .map((m): Resource => {
        const owner = owners?.find((o) => `${o.id}` === `${m.rentalCategory}`);
        const equipmentAssignments = assignments.filter((a) => a.equipment?.internalNumber === m.internalNumber);
        let sortOrder = `${m.mainCategoryName}${m.subCategoryName}${m.internalNumber}${m.modelName}`;
        if (equipmentAssignments.some((a) => a.status === 'Created')) {
          sortOrder = `0${sortOrder}`;
        }
        if (equipmentAssignments.some((a) => a.status === 'Delivered')) {
          sortOrder = `0${sortOrder}`;
        }
        sortOrder = `1${sortOrder}`
          .trim()
          .toLocaleLowerCase('nb');
        return ({
          ...m,
          id: m.internalNumber,
          title: `${m.internalNumber} - ${m.mainCategoryName}`,
          internalNumber: m.internalNumber,
          extraEquipment: m.mainInternalNumber,
          position: m.currentPosition ? `${m.currentPosition.id} - ${m.currentPosition.projectName}` : ' ',
          equipmentOwner: owner ? owner.responsible : ' ',
          hasOverlapEvents: showOnlyEventOverlap
            && equipmentAssignments.some(
              (a) => a.type === 'Assignment'
                && areIntervalsOverlapping(
                  { start: new Date(a.from), end: ensureAfter((a.status === 'Approved' && a.handInDate) ? new Date(a.handInDate) : new Date(a.to), new Date(a.from)) },
                  timeframe,
                )
                && equipmentAssignments.some(
                  (as) => `${as.id}` !== `${a.id}`
                    && areIntervalsOverlapping(
                      { start: new Date(a.from), end: ensureAfter((a.status === 'Approved' && a.handInDate) ? new Date(a.handInDate) : new Date(a.to), new Date(a.from)) },
                      { start: new Date(as.from), end: ensureAfter((as.status === 'Approved' && as.handInDate) ? new Date(as.handInDate) : new Date(as.to), new Date(as.from)) },
                    ),
                ),
            ),
          isAvailableToday: showAvailableToday && (!equipmentAssignments.some((a) => (
            a.type === 'Assignment'
            && (getOrderStatusName(a) === 'notDelivered' || overlapsTodayPred({ ...a, to: a.handInDate || a.to })))
            || unavailabilities?.some((u) => (
              u.equipmentInternalNumber === m.internalNumber && overlapsTodayPred(u)
            )))),
          sortOrder,
        });
      }) || [];
    return [
      ...assignmentResources,
      ...equipmentResources,
    ];
  }, [assignments, equipment, assignmentResources, unavailabilities, showAvailableToday, showOnlyEventOverlap]);

  // Reset show all when search filter or input resources change
  useEffect(() => {
    setShowAllEquipment(false);
  }, [equipment, searchFilter]);

  const cellContent = useRenderCellContent();

  const FCColumns = useMemo(() => {
    const cols = dataColumns.map((c, i): ColSpec => {
      let width: number|undefined;
      if (i === dataColumns.length - 1) { // Last element
        width = undefined;
      } else if (columnWidths[c]) { // Saved width
        width = columnWidths[c];
      } else if (c === 'internalNumber') { // Default width for internalNumber
        width = 80;
      }
      return ({
        field: c,
        headerContent: (
          <div className={`resize-observer-col-handle col-name-${c}`}>
            {translateColumnName(c)}
          </div>
        ),
        cellContent: (a) => cellContent(c, a),
        width,
      });
    });
    return cols;
  }, [dataColumns, equipment, cellContent]);

  const setColumnWidthDebounce = useDebounceArray((key: EquipmentColumn, value: number) => {
    dispatch(setColumnWidth({ key, value }));
  }, 500);

  const setSidebarWidthDebounce = useDebounce((v: number) => {
    dispatch(setSidebarWidth(v));
  }, 500);

  // Set up observer for columns
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((e, i) => {
        const last = i === entries.length - 1;
        const key = getClassValue(e.target.classList, 'col-name-') as EquipmentColumn|null;
        const value = e.borderBoxSize[0].inlineSize + (last ? 0 : 1);
        if (!key) return;
        setColumnWidthDebounce(key, value);
      });
    });
    const items = document.getElementsByClassName('resize-observer-col-handle');
    for (let i = 0; i < items.length; i += 1) {
      // We have to get the parent of the parent for the size to be correct
      const parentEl = items[i].parentElement?.parentElement;
      if (parentEl) {
        parentEl.classList.add(items[i].classList[1]);
        resizeObserver.observe(parentEl);
      }
    }
    return () => resizeObserver.disconnect();
  }, [FCColumns]);

  // Set up observer for sidebar
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((e) => {
        setSidebarWidthDebounce(e.borderBoxSize[0].inlineSize ?? 500);
      });
    });
    const item = document.querySelector(
      'tr.fc-scrollgrid-section.fc-scrollgrid-section-header > th',
    );
    if (!item) return () => null;
    resizeObserver.observe(item);
    return () => resizeObserver.disconnect();
  }, [FCColumns]);

  const [filteredAssignments, filteredEquipment] = useMemo(() => {
    const tmp = assignments
      .filter((a) => {
        const statusName = getOrderStatusName(a);
        if (showOnlyAssignmentsWithExpiredProject) {
          return isAssignmentProjectExpired(a)
            && (filteredColorFilter.length > 0
              ? filteredColorFilter.some((c) => `${c.id}`.split('/').includes(statusName))
              : true);
        }
        if (showAllOrders && a.type === 'Order') { return true; }
        if (projectFilter.length > 0 && !projectFilter.some((p) => p.id === a.project.id)) { return false; }
        if (filteredColorFilter.length <= 0) { return true; }
        return filteredColorFilter.some((c) => `${c.id}`.split('/').includes(statusName));
      });

    return [
      new Set(tmp.map((a) => a.id)),
      new Set(tmp.map((a) => (a.type === 'Order' ? a.subCategory : a.equipment?.internalNumber))),
    ];
  }, [assignments, filteredColorFilter, projectFilter, getOrderStatusName, showAllOrders, showOnlyAssignmentsWithExpiredProject]);

  const FCResources = useMemo(() => {
    const showUnavailabilities = filteredColorFilter.some((c) => `${c.id}` === 'unavailability');
    const resources = FCResourcesUnfiltered.sort((a, b) => a.sortOrder.localeCompare(b.sortOrder, 'nb')).filter(searchFilter);
    const filtered = showOnlyAssignmentsWithExpiredProject
      // show only assignments with expired project if true
      ? resources.filter((r) => filteredEquipment.has(r.internalNumber ?? ''))
      : resources
        .filter((r) => (showOnlyEventOverlap ? r.hasOverlapEvents : true))
        .filter((r) => (showAvailableToday ? r.isAvailableToday : true))
        // Filter out resources without assignment
        .filter((r) => (
          !onlyResourcesWithEvents
          || filteredEquipment.has(r.id)
          || (showUnavailabilities && unavailabilities?.some((u) => u.equipmentInternalNumber === r.id))
        ));
    if (showAllEquipment) return filtered;
    if (filtered.length < UNEXPANDED_COUNT) return filtered;
    return [...filtered.slice(0, UNEXPANDED_COUNT), {
      id: 'show-more-equipment',
    }];
  }, [
    FCResourcesUnfiltered,
    searchFilter,
    showAllEquipment,
    onlyResourcesWithEvents,
    showAvailableToday,
    filteredAssignments,
    filteredEquipment,
    unavailabilities,
    filteredColorFilter,
    showOnlyAssignmentsWithExpiredProject,
    showOnlyEventOverlap,
  ]);

  const FCEvents = useMemo((): EventInput[] => {
    const timelineBackgroundDummy = (a: EquipmentAssignment[]) => a.reduce((c, v): EventInput[] => {
      if (c.some((x) => x.resourceId === v.subCategory)) return c;
      return [...c, {
        id: `bg${v.id}`,
        resourceId: v.subCategory,
        start: subYears(startOfYear(new Date()), 1),
        end: addYears(endOfYear(new Date()), 1),
        display: 'background',
        color: 'rgb(215, 215, 215)',
      }];
    }, [] as EventInput[]);

    let internalAssignments = [...updatedAssignments];
    const changedAssignments = assignments.map((a) => {
      const updated = internalAssignments.find((x) => x.id === a.id);
      if (!updated) return a;
      if (isAfter(new Date(updated.lastEditTime), new Date(a.lastEditTime))) {
        return updated;
      }
      internalAssignments = internalAssignments.filter((x) => x.id !== a.id);
      return a;
    });

    if (internalAssignments.length !== updatedAssignments.length) setUpdatedAssignments(internalAssignments);

    const mappedAssignments = assignments
      .filter((a) => (showOnlyAssignmentsWithExpiredProject
        ? filteredAssignments.has(`${a.id}`)
        : true))
      .map((a): EventInput => {
        const can = editEquipmentRules(a, isAdmin, isBestiller, userOrg);
        const isFilteredOut = !filteredAssignments.has(`${a.id}`);

        const isChangeRequest = !!internalAssignments.find((x) => x.id === a.id);
        const resourceId = a.type === 'Assignment' ? a.equipment?.internalNumber : a.subCategory;
        const { color, label } = getOrderStatus(a);
        // if assignment is approved and handOut and HandIn exist then the event will use these dates
        const isEditLocked = a.status === 'Approved' && !!a.handOutDate;
        const start = a.handOutDate ? new Date(a.handOutDate) : new Date(a.from);
        const end = (() => {
          if (label === 'Ikke innlevert') return new Date();
          if (a.handInDate) return new Date(a.handInDate);
          return new Date(a.to);
        })();

        return ({
          ...a,
          id: a.id,
          title: a.project.projectName,
          start,
          end,
          resourceId,
          parent: resourceId,
          origin: a.project.id.toString(),
          borderColor: color,
          backgroundColor: isFilteredOut ? 'rgba(0,0,0,0)' : color,
          textColor: isFilteredOut ? color : 'white',
          ...allowedEventMovement(
            // allowEdit
            can.editTime,
            // allowStart
            !a.handOutDate,
            // allowEnd
            !a.handInDate,
            // allowHorizontal
            !isEditLocked && !isChangeRequest,
            // allowVertical
            !isEditLocked && can.editEquipment && !isChangeRequest,
          ),
        });
      });

    const visibleResources = new Set<string | undefined>(FCResources.map((r) => r.id));
    return [
      ...holidays,
      ...mappedAssignments,
      ...timelineBackgroundDummy(changedAssignments),
      ...eventifyUnavailability(
        unavailabilities || [],
        onlyResourcesWithEvents && filteredColorFilter.every((c) => c.id !== 'unavailability'),
        isAdmin,
      ),
    ].filter((e) => visibleResources.has(e.resourceId));
  }, [
    FCResources,
    assignments,
    userOrg,
    holidays,
    unavailabilities,
    filteredAssignments,
    onlyResourcesWithEvents,
    filteredColorFilter,
  ]);

  const leftHeader = useMemo(() => (
    <LeftHeader filters={leftFilters} />
  ), [leftFilters]);

  const unavailabilityChanged = (e: EventResizeDoneArg | EventDropArg | EventReceiveArg) => {
    if (!e.event.start || !e.event.end) return;
    const unavailability = unavailabilities?.find((u) => u.id === e.event.id);
    if (!unavailability) return;
    const body: NewUnavailability = {
      from: formatISO(e.event.start),
      to: formatISO(e.event.end),
      comment: unavailability.comment,
    };
    updateUnavailability({
      equipmentId: unavailability.equipment.internalNumber || unavailability.equipmentInternalNumber,
      id: unavailability.id,
      body,
    });
  };

  const copyAssignment = (id: string|undefined) => {
    setContextMenu(null);
    if (id === undefined) return;
    postAssignmentCopy(id).unwrap()
      .catch(() => {
        toast.error('Kunne ikke kopiere tildeling');
      });
  };

  const handleContextMenu = (event: MouseEvent, id: string|undefined) => {
    event.preventDefault();
    if (id === undefined) return;
    if (!isAdmin) return;
    if (!assignments.some((a) => a.id === id && a.type === 'Assignment')) return;

    setContextMenu({ id, mouseX: event.clientX + 2, mouseY: event.clientY - 6 });
  };

  const dailyRentalPrices = useMemo(
    () => new Map(equipment.map((r) => ([r.internalNumber, r.dailyRentalPrice]))),
    [equipment],
  );

  const eventContent = useCallback((e: EventContentArg) => {
    const event = assignments.find((a) => a.id === e.event.id);
    const orderer = workers?.find((w) => w.employeeNumber === event?.ordererEmployeeNumber)?.fullName || '';
    const dailyCostPrice = event?.type === 'Assignment' ? `- ${dailyRentalPrices.get(event.equipment.internalNumber) || 0} kr/dag` : '';
    let title: string|ReactNode|null = null;
    let isComment: boolean = false;
    let isProjectExpired: boolean = false;
    let whiteArrow: boolean = false;
    let tooltipContent: ReactNode = null;
    if (event) {
      title = `${event.project.id} - ${event.project.projectName} - ${orderer} ${dailyCostPrice}`;
      isComment = !!event?.orderComment || !!event?.comment;
      isProjectExpired = isAssignmentProjectExpired(event);
      whiteArrow = true;
      tooltipContent = (event ? (
        <HoverEvent
          task={event}
        />
      ) : (
        ''
      ));
    } else {
      const unavailability = unavailabilities?.find((u) => u.id === e.event.id);
      if (unavailability) {
        title = e.event.title;
        isComment = !!unavailability.comment;
        tooltipContent = (
          <Box display="flex" flexDirection="column" gap={1.5}>
            <span className="bold font-size-12"> {title} </span>
            {unavailability?.from && unavailability.to ? (
              <span>{`Tidsrom: ${format(new Date(unavailability.from), 'dd.MM.yyyy')} - ${format(new Date(unavailability.to), 'dd.MM.yyyy')}`}</span>
            ) : (
              null
            ) }
            <span>{unavailability.comment}</span>
            <Box> <LastEditInfo data={unavailability} darkMode /> </Box>
          </Box>
        );
      } else {
        title = e.event.title;
        tooltipContent = e.event.title;
      }
    }
    return (
      <Tooltip
        title={tooltipContent}
        followCursor
        placement="right-start"
        enterDelay={1000}
        enterNextDelay={1000}
        arrow
      >
        <Box
          className={`fc-event-main-frame ${whiteArrow ? 'white-arrow' : ''} ${isProjectExpired ? 'project-expired' : ''}`}
          gap={isComment && isProjectExpired ? 0.2 : 0.1}
        >
          { isComment && (
            <EventCommentIcon />
          )}
          { isProjectExpired && (
            <EventProjectExpiredIcon />
          )}
          <span className={`fc-event-title fc-sticky ${isProjectExpired ? 'bold' : ''}`}>
            {title}
          </span>
        </Box>
      </Tooltip>
    );
  }, [equipment, assignments, unavailabilities]);

  const eventClicked = useCallback((e: EventClickArg) => {
    const clickedAssignment = assignments?.find((a) => a.id === e.event.id);
    if (clickedAssignment) {
      return setActiveEvent(clickedAssignment);
    }
    const clickedUnavailability = unavailabilities?.find((u) => u.id === e.event.id);
    if (clickedUnavailability) {
      return setSelectedUnavailability(clickedUnavailability);
    }
    return null;
  }, [assignments, unavailabilities]);

  const [newAssignment, setNewAssignment] = useState<{fromDate: Date, toDate: Date, equip: Equipment} | null>(null);

  const addAssignment = (a: DateSelectArg) => {
    // Clear selection
    calRef.current?.getApi()?.unselect();

    const equip = equipment.find((e) => e.internalNumber === a.resource?.id);
    if (!equip) return;

    const fromDate = addHours(startOfDay(a.start), 7);
    const toDate = addHours(startOfDay(a.end), 15 - 24);
    setNewAssignment({
      fromDate,
      toDate,
      equip,
    });
  };

  const eventChanged = (e: EventResizeDoneArg | EventDropArg | EventReceiveArg) => {
    if (!e || !e?.event) return;

    const equipmentOrder = assignments.find((a) => a.id === e.event.id);
    if (!equipmentOrder) {
      unavailabilityChanged(e);
      return;
    }
    // assignments cant be placed on dummy resources (order row)
    if (equipmentOrder.type !== 'Order' && Number.isNaN(parseInt(e.event.getResources()[0].id, 10))) {
      e.revert();
      toast.error('Ugyldig flytting av tildeling');
      return;
    }
    if (Number.isNaN(parseInt(e.event.getResources()[0].id, 10)) && e.event.start && e.event.end) {
      if (equipmentOrder.type === 'Order') {
        const orderBody: MoveEquipmentOrder = {
          from: formatISO(e.event.start),
          to: formatISO(e.event.end),
          category: equipmentOrder.category,
          subCategory: equipmentOrder.subCategory,
          projectId: equipmentOrder.project.id,
          comment: equipmentOrder.orderComment,
          worksite: equipmentOrder.worksite,
          ordererEmployeeNumber: equipmentOrder.ordererEmployeeNumber as number,
        };
        changeOrder({ id: e.event.id, body: orderBody })
          .unwrap()
          .then((response) => {
            setUpdatedAssignments([...updatedAssignments, response]);
          }).catch(() => e.revert());
        return;
      }
    }
    if (e.event.start && e.event.end && e.event.getResources()[0].id && equipmentOrder.type === 'Order') {
      const approvebody: ApproveEquipmentOrder = {
        from: formatISO(e.event.start),
        to: formatISO(e.event.end),
        projectId: equipmentOrder.project.id,
        equipmentInternalNumber: e.event.getResources()[0].id,
        comment: '',
        ordererEmployeeNumber: equipmentOrder.ordererEmployeeNumber as number,
      };
      approveOrder({ id: e.event.id, body: approvebody })
        .then((response) => {
          if ('data' in response) {
            setUpdatedAssignments([...updatedAssignments, response.data]);
            setIsDragging(null);
          }
        });
    }

    if (!Number.isNaN(parseInt(e.event.getResources()[0].id, 10)) && e.event.start && e.event.end) {
      if (equipmentOrder.type === 'Assignment') {
        const assignmentBody: MoveEquipmentAssignment = {
          from: formatISO(e.event.start),
          to: formatISO(e.event.end),
          handInDate: equipmentOrder?.handInDate,
          handOutDate: equipmentOrder?.handOutDate,
          projectId: equipmentOrder.project.id,
          equipmentInternalNumber: e.event.getResources()[0].id,
          comment: equipmentOrder.comment,
          ordererEmployeeNumber: equipmentOrder.ordererEmployeeNumber as number,
        };
        changeAssignment({ id: e.event.id, body: assignmentBody })
          .unwrap()
          .then((assignment) => {
            setUpdatedAssignments([...updatedAssignments.filter((a) => a.id !== assignment.id), assignment]);
            setIsDragging(null);
          }).catch(() => e.revert());
      }
    }
  };

  return (
    <Box className="full-calendar-wrapper">
      <div className={`calendar-wrapper${loading ? ' loading' : ''}`}>
        <TimelineHeader
          day={date}
          currentView={view}
          onDayChange={setDate}
          onCalendarViewChange={setView}
        />
        <div className={`calendar-inner${view === 'kvartal' || view === 'ar' ? ' fc-hide-lines' : ''}`}>
          <FullC
            ref={calRef}
            plugins={[resourceTimelinePlugin, interactionPlugin]}
            selectable={isAdmin}
            select={isAdmin ? addAssignment : () => undefined}
            initialView="resourceTimelineDay"
            initialDate={date}
            headerToolbar={false}
            events={FCEvents}
            eventContent={eventContent}
            eventClick={eventClicked}
            eventDrop={eventChanged}
            eventResize={eventChanged}
            eventDidMount={(arg) => {
              arg.el.addEventListener('contextmenu', (jsEvent) => {
                jsEvent.preventDefault();
                handleContextMenu(jsEvent, arg.event.id);
              });
            }}
            resources={FCResources}
            resourceAreaHeaderContent={leftHeader}
            resourceAreaColumns={FCColumns}
            resourceOrder="sortOrder"
            resourceLabelDidMount={({ resource, el }) => {
              if (!el?.parentElement) return;
              // eslint-disable-next-line no-param-reassign
              el.parentElement.className = `resource-style${Number.isNaN(parseInt(`${resource.id}`, 10))
                ? ' dummy'
                : ''}`;
              if (Number.isNaN(parseInt(resource.id, 10))) return;
              // eslint-disable-next-line no-param-reassign
              el.parentElement.onclick = () => {
                onLeftSelect(parseInt(resource.id, 10), resource.title);
                setActiveEquipment({ id: parseInt(resource.id, 10), title: resource.title });
              };
            }}
            rerenderDelay={200}
            resourceAreaWidth={sidebarWidth}
            nowIndicator
            droppable
            editable
            height="100%"
            locale={nbLocale}
            weekends={showWeekend}
            scrollTimeReset={false}
            displayEventTime={false}
            businessHours={{
              daysOfWeek: [1, 2, 3, 4, 5],
              startTime: '07:00',
              endTime: '15:00',
            }}
            visibleRange={firstRun ? undefined : timeframe}
            slotLabelClassNames="slot-label"
            views={{
              maned: {
                type: 'resourceTimeline',
                slotDuration: { days: 1 },
                slotLabelFormat: [{ week: 'long' }, { weekday: 'short', day: '2-digit' }],
                slotLabelContent: (l) => {
                  switch (l.level) {
                    case 0: return format(l.date, "'Uke' w");
                    case 1: return format(l.date, "E'.' dd").replace(/\w\./, '.');
                    default: return '';
                  }
                },
                slotMinWidth: 52.5,
              },
              kvartal: {
                type: 'resourceTimeline',
                slotDuration: { days: 1 },
                snapDuration: { days: 1 },
                slotLabelFormat: [{ month: 'long' }, { week: 'long' }],
                slotLabelInterval: { days: 7 },
                slotMinWidth: 10,
                slotLabelContent: (l) => {
                  switch (l.level) {
                    case 0: return format(l.date, 'MMMM');
                    case 1: return format(l.date, "'Uke' w");
                    default: return '';
                  }
                },
              },
              ar: {
                type: 'resourceTimeline',
                slotDuration: { days: 1 },
                slotLabelFormat: [{ month: 'long' }, { week: 'numeric' }],
                slotLabelInterval: { days: 7 },
                slotMinWidth: 5,
                slotLabelContent: (l) => {
                  switch (l.level) {
                    case 0: return format(l.date, 'MMMM');
                    case 1: return format(l.date, 'w');
                    default: return '';
                  }
                },
              },
            }}
                  // Not a secret since it can easily be spotted in minified files
                  // no matter where or how it is stored
            schedulerLicenseKey="0067403127-fcs-1701517614"
          />
        </div>
      </div>
      {loading && (
      <CircularProgress
        size={50}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          marginTop: '-12px',
          marginLeft: '-12px',
          zIndex: 1000,
        }}
      />
      )}
      <Menu
        open={contextMenu !== null}
        onClose={() => setContextMenu(null)}
        anchorReference="anchorPosition"
        anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
      >
        <MenuItem onClick={() => copyAssignment(contextMenu?.id)}>Kopier</MenuItem>
        <MenuItem onClick={() => setContextMenu(null)}>Avbryt</MenuItem>
      </Menu>
      { selectedUnavailability && (
      <Modal
        title="Endre utilgjengelighet"
        onClose={() => setSelectedUnavailability(null)}
      >
        <EditUnavailability
          equipmentId={selectedUnavailability?.equipment.internalNumber || ''}
          id={selectedUnavailability?.id}
          onAction={() => setSelectedUnavailability(null)}
        />
      </Modal>
      )}
      {activeEquipment !== null && (
        <Modal
          title={(
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {activeEquipment.title} -
              <a target="_blank" href={`https://mm2.barde.app/#/machine/${activeEquipment.id}`} rel="noreferrer">
                Teknisk
              </a>
            </Box>
          )}
          compactHeader
          onClose={() => setActiveEquipment(null)}
        >
          <EquipmentCard id={activeEquipment.id} />
        </Modal>
      )}
      {activeEvent !== null && (
        <EquipmentOrderModal
          open={activeEvent !== null}
          onClose={() => setActiveEvent(null)}
          assignmentId={activeEvent.id}
          can={editEquipmentRules(activeEvent, isAdmin, isBestiller, userOrg)}
          allEquipment={allEquipment}
          projects={modalProjects}
          isEdit
          refetch={refetchAssignments}
        />
      )}
      <NewEquipmentOrderModal
        open={newAssignment !== null}
        onClose={() => setNewAssignment(null)}
        allEquipment={allEquipment}
        fromDate={newAssignment?.fromDate}
        toDate={newAssignment?.toDate}
        equipment={newAssignment?.equip}
      />
    </Box>
  );
};
