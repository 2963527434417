/**
 * Extract a value from a class name. The classname should be of the format
 * `${token}${value}` where token is a unique prefix that's passed in as
 * `startsWith`, and value is the return value of this function.
 * @param items DOM Tokens
 * @param startsWith Token that the property should start with
 * @returns Value after token
 */
export const getClassValue = (items: DOMTokenList, startsWith: string) => {
  for (let i = 0; i < items.length; i += 1) {
    if (items[i].startsWith(startsWith)) {
      return items[i].replace(new RegExp(`^${startsWith}`), '');
    }
  }
  return null;
};
