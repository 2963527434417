import {
  FC,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Box,
  CircularProgress,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import { isAfter } from 'date-fns';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { format } from '../../../shared/logic/dates';
import { DataField } from '../../../shared/components/dataField';
import { GpsChip } from '../../../shared/components/gpsChip';
import { useHasRoles } from '../../../core/helpers/useHasRoles';
import {
  useGetAllProjectsQuery,
  useGetEquipmentQuery,
  useGetExtraEquipmentQuery,
  usePostEquipmentPositionMutation,
  usePutEquipmentCommentMutation,
} from '../../../shared/redux/rental';
import { SearchSelect } from '../../../shared/components/searchSelect';
import { SelectItem } from '../../../shared/types/util/selectItem';
import { Modal } from '../../../shared/components/modal';
import { Button } from '../../../shared/components/button';

export const InfoTab: FC<{id: number}> = ({ id }) => {
  const { data: equipment, isLoading } = useGetEquipmentQuery(id);
  const { data: projects } = useGetAllProjectsQuery();
  const [putComment, { isLoading: isCommentUpdating }] = usePutEquipmentCommentMutation();
  const [postPosition, { isLoading: isPositionUpdating }] = usePostEquipmentPositionMutation();
  const { data } = useGetExtraEquipmentQuery(equipment?.internalNumber || skipToken);
  const extraEquipment = useMemo(() => data ?? [], [data]);
  const [comment, setComment] = useState<string>('');
  const [position, setPosition] = useState<SelectItem|undefined>();
  const [isPositionOpen, setIsPositionOpen] = useState<boolean>(false);
  const isAdmin = useHasRoles('internutleie-koordinator');

  useEffect(() => {
    setComment(equipment?.comment || '');
    setPosition(equipment?.currentPosition
      ? { id: equipment.currentPosition.id, label: `${equipment.currentPosition.id} - ${equipment.currentPosition.projectName}` }
      : undefined);
  }, [equipment]);

  const updateComment = () => {
    if (!isAdmin) return;
    if (equipment?.comment === comment || (!equipment?.comment && comment === '')) return;
    if (comment.trim().length === 0) setComment('');
    putComment({ id, comment });
  };

  const projectList: SelectItem[] = projects?.map((p) => ({ id: p.id, label: `${p.id} - ${p.projectName}` })) || [];

  if (!equipment || isLoading) {
    return (
      <div className="project-details">
        <Box display="flex" alignItems="flex-start">
          <Box flex={1} display="flex" flexDirection="column" gap={2}>

            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={300} />

          </Box>
          <Box flex={1} display="flex" flexDirection="column" gap={2}>
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
          </Box>

          <Box flex={1} display="flex" flexDirection="column" gap={2}>
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
          </Box>
        </Box>
      </div>
    );
  }
  return (
    <div>
      <Box display="flex" alignItems="stretch" flexDirection="column" gap={2}>
        <Box flex={1} display="flex" flexDirection="row" gap={1}>

          <TextField
            fullWidth
            value={!equipment.vehicleRegistrationPlateNumber
              ? ' '
              : equipment.vehicleRegistrationPlateNumber}
            label="Registreringsnummer"
            variant="standard"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{ sx: { fontSize: 17 } }}
          />

          <TextField
            fullWidth
            value={!equipment.mainCategoryName
              ? ' '
              : equipment.mainCategoryName}
            label="Kategori"
            variant="standard"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{ sx: { fontSize: 17 } }}
          />

          <TextField
            fullWidth
            value={!equipment.subCategoryName
              ? ' '
              : equipment.subCategoryName}
            label="Underkategori"
            variant="standard"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{ sx: { fontSize: 17 } }}
          />

        </Box>
        <Box flex={1} display="flex" flexDirection="row" gap={1}>

          <TextField
            fullWidth
            value={!equipment.modelName
              ? ' '
              : equipment.modelName}
            label="Modelnavn"
            variant="standard"
            multiline
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{ sx: { fontSize: 17 } }}
          />

          <TextField
            fullWidth
            value={!equipment.manufacturerName
              ? ' '
              : equipment.manufacturerName}
            label="Produsent"
            variant="standard"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{ sx: { fontSize: 17 } }}
          />

          <TextField
            fullWidth
            value={!equipment.modelYear
              ? ' '
              : equipment.modelYear}
            label="Modelår"
            variant="standard"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{ sx: { fontSize: 17 } }}
          />

        </Box>

        <Box flex={1} display="flex" flexDirection="row" gap={1}>

          <DataField
            label="Dagsleiepris"
            sx={{ flex: 1 }}
          >
            {!equipment.dailyRentalPrice
              ? ' '
              : `${equipment.dailyRentalPrice} kr/dag`}
          </DataField>

          <DataField
            label="Salgspris"
            sx={{ flex: 1 }}
          >
            {!equipment.sellingPrice
              ? ' '
              : `${equipment.sellingPrice} Kr`}
          </DataField>

          {/* empty layout element */}
          <Box flex={1} />

        </Box>

        <Box flex={1} display="flex" flexDirection="row" gap={1}>

          <TextField
            fullWidth
            multiline
            value={equipment.info || ' '}
            label="Info"
            variant="standard"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{ sx: { fontSize: 17 } }}
          />

          <TextField
            fullWidth
            value={!equipment.quickCouplingAttachmentSize
              ? ' '
              : `${equipment.quickCouplingAttachmentSize}`}
            label="HK-Feste str"
            variant="standard"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{ sx: { fontSize: 17 } }}
          />

          <TextField
            fullWidth
            value={!equipment.tiltRotatorType
              ? ' '
              : `${equipment.tiltRotatorType}`}
            label="Rotortilt type"
            variant="standard"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{ sx: { fontSize: 17 } }}
          />

        </Box>

        <Box flex={1} display="flex" flexDirection="row" gap={1}>

          <DataField
            label="Fast fører"
            sx={{ flex: 1 }}
          >
            {equipment.assignedDriverName ? equipment.assignedDriverName : ''}
          </DataField>

          <DataField
            label="Vikar"
            sx={{ flex: 1 }}
          >
            {equipment.substituteDriverName ? equipment.substituteDriverName : ''}
          </DataField>

          <DataField
            label="Gangtid"
            sx={{ flex: 1 }}
          >
            {
              `${equipment.operatingTime ?? ''}`.trim()
                ? `${equipment.operatingTime} KM/TIMER`
                : ''
            }
          </DataField>
        </Box>

        <Box flex={1} display="flex" flexDirection="row" gap={1}>

          <DataField
            label="GPS fabrikat"
            sx={{ flex: 1 }}
          >
            {equipment.gpsMake ? <GpsChip gps={equipment.gpsMake} /> : 'Har ikke GPS'}
          </DataField>

          <DataField
            label="Siste CE godkjennelse"
            sx={{ flex: 1 }}
          >
            <Box display="flex" gap={1} alignItems="center" color="black">

              {!equipment.previousCeMarkingAt
                ? ' '
                : format(new Date(equipment.previousCeMarkingAt), 'dd.MM.yyyy')}
              {isAfter(new Date(), new Date(equipment.previousCeMarkingAt))
                ? (
                  <Tooltip
                    title="CE godkjennelse utgått"
                    followCursor
                    placement="right-start"
                    enterDelay={300}
                    enterNextDelay={300}
                    arrow
                  >
                    <EventBusyIcon color="error" sx={{ alignSelf: 'center', fontSize: '25px' }} />
                  </Tooltip>
                )
                : null}
            </Box>
          </DataField>

          {/* empty layout element */}
          <Box flex={1} />
        </Box>

        <Box flex={1} display="flex" flexDirection="row" gap={1}>
          <DataField
            label="Nåværende plassering"
            sx={{ flex: 1 }}
          >
            {equipment.currentPosition ? `${equipment.currentPosition.id} - ${equipment.currentPosition.projectName}` : ' '}
          </DataField>

          {isAdmin ? (
            <Box flex={1} alignSelf="center">
              <Button
                loading={isPositionUpdating}
                variant="outlined"
                onClick={() => setIsPositionOpen(true)}
              >
                Endre plassering
              </Button>
            </Box>
          ) : (
          // empty layout element
            <Box flex={1} />
          )}
          {/* empty layout element */}
          <Box flex={1} />
        </Box>

        {extraEquipment.length > 0 && (
        <DataField sx={{ flex: 1, overflowX: 'hidden' }} label="Ekstrautstyr">
          <Box sx={{ width: '100%' }}>
            {extraEquipment.map((ee) => (
              <Typography
                key={ee.internalNumber}
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >{`${ee.internalNumber} - ${ee.subCategoryName} - ${ee.modelName} (${ee.dailyRentalPrice} kr/dag)`}
              </Typography>
            ))}
          </Box>
        </DataField>
        )}

        {equipment.commentTek && equipment.commentTek !== '0' && (
          <TextField
            fullWidth
            multiline
            value={equipment.commentTek || ' '}
            label="Kommentar (teknisk)"
            variant="standard"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{ sx: { fontSize: 17 } }}
          />
        )}
        {equipment.commentMP && equipment.commentMP !== '0' && (
          <TextField
            fullWidth
            multiline
            value={equipment.commentMP || ' '}
            label="Kommentar (MP)"
            variant="standard"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{ sx: { fontSize: 17 } }}
          />
        )}

        <TextField
          fullWidth
          multiline
          rows={3}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          disabled={!isAdmin}
          label="Kommentar"
          variant="outlined"
          InputLabelProps={{ sx: { fontSize: 17 }, shrink: true }}
        />
      </Box>
      <Box display={isAdmin ? 'flex' : 'none'} paddingTop={2.5} justifyContent="flex-end">
        <Button
          disabled={isCommentUpdating
                || equipment.comment === comment
                || (!equipment.comment && (comment === '' || comment.trim().length === 0))}
          variant="contained"
          onClick={updateComment}
        >
          {isCommentUpdating ? <CircularProgress size={24} /> : 'Lagre'}
        </Button>
      </Box>

      <Modal
        title="Endre plassering"
        onClose={() => setIsPositionOpen(false)}
        maxWidth="sm"
        open={isPositionOpen}
      >
        <Box display="flex" flexDirection="column" gap={2}>
          <SearchSelect
            label="Posisjon"
            loading={isLoading || isPositionUpdating}
            value={position}
            onChange={(p) => setPosition(p)}
          >
            {projectList}
          </SearchSelect>
          <Box display="flex" gap={1} flexDirection="row-reverse">
            <Button
              variant="contained"
              onClick={() => {
                postPosition({ id: `${id}`, projectId: position?.id as number });
                setIsPositionOpen(false);
              }}
            >Lagre
            </Button>
            <Button variant="outlined" onClick={() => setIsPositionOpen(false)}>Avbryt</Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
