import {
  Box,
  Checkbox,
  SxProps,
  Typography,
} from '@mui/material';
import './ListItem.scss';

export type ListItemDataType = {
  id: string|number,
  internalNumber: string,
  fromProject: string,
  category: string,
  model: string,
  checked: boolean,
  extraEquipment: {
    internalNumber: string,
    category: string,
    model: string,
    checked: boolean,
  }[],
}

const textSx: SxProps = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  flex: 1,
  width: '0px',
};

export const ListItem = ({
  item,
  onChange = () => null,
}: {
  item: ListItemDataType,
  onChange?: (i: ListItemDataType) => void,
}) => {
  const toggleParent = () => {
    onChange({ ...item, checked: !item.checked });
  };
  const toggleExtraEquipment = (internalNumber: string) => () => {
    onChange({
      ...item,
      extraEquipment: item.extraEquipment.map((e) => (
        e.internalNumber !== internalNumber
          ? e
          : { ...e, checked: !e.checked }
      )),
    });
  };
  return (
    <Box sx={{
      // backgroundColor: '#0000000b',
      borderBottom: '1px solid #bbb',
      '&:last-child': { borderBottom: 'none' },
    }}
    >
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        backgroundColor: 'white',
      }}
      >
        <Checkbox checked={item.checked} onClick={toggleParent} />
        <Box width={24} />
        <Typography sx={{ width: 100 }}>{item.internalNumber}</Typography>
        <Typography sx={{ ...textSx, flex: 1 }}>{item.category} / {item.model}</Typography>
        <Typography sx={{ ...textSx, flex: 1 }}>{item.fromProject}</Typography>
      </Box>
      {item.extraEquipment.map((e) => (
        <Box key={e.internalNumber} className="subitem" sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Box className="arrow" sx={{ width: 24 }} />
          <Checkbox checked={e.checked} onClick={toggleExtraEquipment(e.internalNumber)} />
          <Typography sx={{ width: 100 }}>{e.internalNumber} </Typography>
          <Typography sx={{ ...textSx, flex: 1 }}>{e.category} / {e.model}</Typography>
        </Box>
      ))}
    </Box>
  );
};
