import { FC } from 'react';
import {
  Box,
} from '@mui/material';
import { timestringFormat } from '../../shared/logic/dates';
import { EquipmentAssignment } from '../../shared/types/equipmentAssignment';
import './style.scss';

const getOrderedBy = (task: EquipmentAssignment) => {
  if (task.type === 'Order') {
    return { timestamp: task.createdTimestamp, by: task.createdByName };
  }
  return { timestamp: task.order.createdTimestamp, by: task.order.createdByName };
};

export const HoverEvent:FC<{
  task: EquipmentAssignment,
}> = ({
  task,
}) => {
  if (!task) return null;
  const { timestamp, by } = getOrderedBy(task);

  return (
    <Box>
      <div>
        <div className="header mb-m">
          <span>Prosjekt: </span>
          <span className="bold">{`${task.project.id} - ${task.project.projectName}`}</span>
        </div>
        <div className="content mb-s">
          <span>Bestilt {timestringFormat(timestamp)} av {by}</span>
        </div>
        {task.status === 'Approved' && (
        <div className="content mb-s">
          <span>Godkjent {timestringFormat(task.approvedTime ?? task.lastEditTime)} av {task.approvedByName ?? task.lastEditedByName}</span>
        </div>
        )}
        {task.type === 'Assignment' && (
          <div className="content mb-s">
            <span>Dagsleiepris: </span>
            <span>{`${task?.equipment?.dailyRentalPrice || 0} kr/dag`}</span>
          </div>
        )}
        {task.orderComment && (
        <>
          <div className="content bold mt-m mb-s">Bestillingskommentar</div>
          <div className="content mb-s">{task.orderComment}</div>
        </>
        )}
        {task.comment && (
        <>
          <div className="content bold mt-m mb-s">Internkommentar</div>
          <div className="content mb-s">{task.comment}</div>
        </>
        )}
      </div>
    </Box>
  );
};
