import InfoIcon from '@mui/icons-material/Info';

export const EventProjectExpiredIcon = () => (
  <InfoIcon
    sx={{
      alignSelf: 'center',
      fontSize: '14px',
      justifySelf: 'center',
      transform: 'translateY(0.5px)',
    }}
    fontSize="small"
  />
);
