import { Box, IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { isBefore } from 'date-fns';
import { Button } from '../../shared/components/button';
import { DateTimePicker } from '../../shared/components/dateTimePicker';

export const ClearableDateTimePicker = ({
  date,
  minDate = undefined,
  defaultDate = new Date(),
  disabled = false,
  disableClear = false,
  size = 'medium',
  btnTxt,
  label,
  onChange,

} : {
 date: Date|undefined,
 minDate?: Date|undefined,
 /** Date to set when button is pressed - honours min date */
 defaultDate?: Date,
 disabled?: boolean,
 disableClear?: boolean,
 size?: 'small' | 'medium',
 btnTxt: string,
 label: string,
 onChange: (d: Date|undefined) => void,
}) => (
  <Box sx={{ flex: 1 }}>
    {date ? (
      <Box display="flex" alignItems="center">
        <DateTimePicker
          format="dd.MM.yyyy"
          disableTime
          size={size}
          fullWidth
          label={label}
          value={date}
          minDate={minDate}
          onChange={onChange}
          closeOnSelect
          disabled={disabled}
        />
        {!disableClear && (
          <IconButton
            sx={{ borderRadius: '4px', marginLeft: 1 }}
            onClick={() => onChange(undefined)}
            disabled={disabled}
          >
            <CancelIcon />
          </IconButton>
        )}
      </Box>
    ) : (
      <Button
        color="info"
        variant="contained"
        disabled={disabled}
        size={size}
        fullWidth
        sx={{ height: size === 'medium' ? '56px' : '40px' }}
        onClick={() => {
          const d = (() => {
            if (!minDate) return defaultDate;
            if (isBefore(defaultDate, minDate)) return minDate;
            return defaultDate;
          })();
          onChange(d);
        }}
      >
        {btnTxt}
      </Button>
    )}
  </Box>

);
