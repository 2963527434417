import { FC, ReactNode } from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
} from '@mui/material';
import { PopMenu } from '../../../../shared/components/popMenu';
import {
  EquipmentColumn,
  setColumns,
  setSearchString,
  useColumnNames,
  useColumns,
} from '../../../../core/redux/leftFilterState';
import { useDispatch, useSelector } from '../../../../shared/hooks/redux';
import { MiniSearch } from '../../../../shared/components/miniSearch';
import { PopMenuHeader } from '../../../../shared/components/popMenuHeader';
import { translateColumnName } from '../../../../core/helpers/translate';
import { FilterIcon } from '../../../../shared/components/filterIcon';

export const LeftHeader: FC<{
  filters?: ReactNode
}> = ({
  filters,
}) => {
  const searchString = useSelector((s) => s.leftFilterState.searchString);

  const isFiltersActive = useSelector(({ leftFilterState: state }) => {
    const { equipment } = state;

    if (state.managers?.length) return true;
    if (state.orderers?.length) return true;
    if (equipment.sortingFilter?.length) return true;
    if (equipment.couplingSizeFilter?.length) return true;
    if (equipment.tiltRotatorFilter?.length) return true;

    return false;
  });

  const columns = useColumnNames();
  const activeColumns = useColumns();
  const dispatch = useDispatch();
  const toggleColumn = (column: EquipmentColumn) => {
    const state = (activeColumns as EquipmentColumn[]).includes(column);
    if (state) {
      dispatch(setColumns({ equipment: (activeColumns as EquipmentColumn[]).filter((c) => c !== column) }));
    } else {
      dispatch(setColumns({ equipment: [...(activeColumns as EquipmentColumn[]), column] }));
    }
  };

  return (
    <div className="left-header-partial">
      <span className="header-text">
        Utstyr
      </span>
      <Box maxWidth={200} width="100%" textAlign="right" pr={2} pl={2}>
        <MiniSearch value={searchString} onChange={(s) => dispatch(setSearchString(s))} />
      </Box>
      <span>
        <Box display="flex" flex={1} gap={3} justifyContent="flex-end">
          <PopMenu
            button={<IconButton><FilterIcon filterActive={isFiltersActive} /></IconButton>}
            width={filters === undefined ? '150px' : '225px'}
          >
            <Box display="flex" flexDirection="column" gap={0.5}>
              {filters !== undefined && (
              <span>
                <PopMenuHeader>Filter</PopMenuHeader>
                {filters}
              </span>
              )}
              <Box>
                <PopMenuHeader>Kolonner</PopMenuHeader>
                {columns.map((column) => (
                  <FormGroup key={column}>
                    <FormControlLabel
                      label={translateColumnName(column)}
                      checked={(activeColumns as EquipmentColumn[]).includes(column)}
                      onChange={() => { toggleColumn(column); }}
                      disabled={activeColumns.length === 1 && activeColumns.includes(column)}
                      control={<Checkbox />}
                    />
                  </FormGroup>
                ))}
              </Box>
            </Box>
          </PopMenu>
        </Box>
      </span>
    </div>
  );
};
