import {
  useMemo,
  useState,
  useEffect,
} from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
} from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { endOfDay, formatISO } from 'date-fns';
import { toast } from 'react-toastify';
import { WorkerPicker } from '../workerPicker';
import { getLoggedInRentalUserObject } from '../../hooks/getLoggedInRentalUserObject';
import { useDispatch, useSelector } from '../../hooks/redux';
import { dateWithoutTimezone, ensureAfter } from '../../logic/dates';
import {
  reset,
  setOrderedBy,
  setFrom,
  setTo,
  setActiveExtraEquipment,
  setMainCategory as setMainCat,
  setSubCategory as setSubCat,
  setSelectedEquipment,
  useIsFormValid,
  setProject,
  setWorksite,
  setComment,
} from '../../redux/local/rentalOrderState';
import {
  useGetCategoriesQuery,
  usePostEquipmentOrderMutation,
  usePostCreateAssignmentMutation,
  useGetExtraEquipmentQuery,
  useGetAllProjectsQuery,
  useGetEquipmentAssignmentsForQuery,
  usePostCreateAndApproveAssignmentMutation,
} from '../../redux/rental';
import { MoveEquipmentAssignment } from '../../types/api/moveEquipmentAssignment';
import { NewEquipmentOrder as NewOrder } from '../../types/api/newEquipmentOrder';
import { Equipment } from '../../types/equipment';
import { SelectItem } from '../../types/util/selectItem';
import { DataField } from '../dataField';
import { SearchSelect } from '../searchSelect';
import { Checkbox } from '../checkbox';
import { DateTimePicker } from '../dateTimePicker';
import { labelSort } from '../../logic/functions';
import { createOverlapMessage } from '../../hooks/useOverlapMessage';
import './style.scss';
import { useEquipmentSearchFilter } from '../../hooks/useEquipmentSearchFilter';

export const NewEquipmentOrder = ({
  fromDate = null,
  toDate = null,
  equipment = null,
  allEquipment,
  onClose = () => null,
} : {
    fromDate?: Date|null,
    toDate?: Date|null,
    equipment?: Equipment|null,
    allEquipment?: Equipment[],
    onClose?: (success: boolean) => void;
  }) => {
  const {
    activeExtraEquipment,
    from: rawFrom,
    to: rawTo,
    project,
    mainCat,
    subCat,
    worksite,
    comment,
    selectedEquipment,
    orderedBy,
  } = useSelector((s) => s.rentalOrderState);

  const [post] = usePostEquipmentOrderMutation();
  const [createAssignment] = usePostCreateAssignmentMutation();
  const [sendAndApprove] = usePostCreateAndApproveAssignmentMutation();
  const { data: rawEquipmentDummies } = useGetCategoriesQuery({ includeSubCategories: true });
  const { data: availableExtraEquipment } = useGetExtraEquipmentQuery(selectedEquipment?.id || equipment?.internalNumber || skipToken);
  const { data: rawProjects } = useGetAllProjectsQuery();

  const [spinner, setSpinner] = useState<boolean>(false);

  const dispatch = useDispatch();

  const from = useMemo(() => new Date(rawFrom), [rawFrom]);
  const to = useMemo(() => new Date(rawTo), [rawTo]);
  const equipmentDummies = useMemo(() => rawEquipmentDummies ?? [], [rawEquipmentDummies]);
  const projects = useMemo(() => rawProjects ?? [], [rawProjects]);

  const { data: overlappingAssignments } = useGetEquipmentAssignmentsForQuery(
    {
      id: equipment?.internalNumber || '',
      from: from !== undefined ? dateWithoutTimezone(from) : undefined,
      to: to !== undefined ? formatISO(endOfDay(to)) : undefined,
    },
    { skip: equipment?.internalNumber === undefined },
  );

  const overlapMessage = useMemo(
    () => (overlappingAssignments !== undefined ? createOverlapMessage(overlappingAssignments) : null),
    [overlappingAssignments],
  );

  // Reset state on close
  useEffect(() => {
    dispatch(reset());
  }, []);

  const loggedInUser = getLoggedInRentalUserObject();

  useEffect(() => {
    if (loggedInUser === null) return;
    dispatch(setOrderedBy(loggedInUser?.employeeNumber));
  }, [loggedInUser]);

  useEffect(() => {
    if (fromDate) dispatch(setFrom(fromDate));
    if (toDate) dispatch(setTo(toDate));
  }, [fromDate, toDate]);

  useEffect(() => {
    dispatch(setActiveExtraEquipment(activeExtraEquipment.filter((e) => availableExtraEquipment?.some((a) => a.internalNumber === e))));
  }, [availableExtraEquipment]);

  const toggleExtraEquipment = (id: string) => () => {
    if (activeExtraEquipment.includes(id)) {
      dispatch(setActiveExtraEquipment(activeExtraEquipment.filter((a) => a !== id)));
    } else {
      dispatch(setActiveExtraEquipment([...activeExtraEquipment, id]));
    }
  };

  const totalCost = useMemo(() => {
    let equipmentCost = 0;
    if (selectedEquipment !== undefined && allEquipment) {
      /** Equipment */
      const eq = allEquipment.find((ae) => ae.internalNumber === selectedEquipment.id);
      if (!eq) return null;
      equipmentCost = eq.dailyRentalPrice;
    } else if (equipment) {
      equipmentCost = equipment.dailyRentalPrice || 0;
    } else {
      return null;
    }
    const extraCost = activeExtraEquipment.map((e) => availableExtraEquipment?.find((a) => a.internalNumber === e)?.dailyRentalPrice || 0).reduce((acc, val) => acc + val, 0);
    return equipmentCost + extraCost;
  }, [equipment, allEquipment, selectedEquipment, activeExtraEquipment, availableExtraEquipment]);

  const mainCategories = useMemo(() => (
    equipmentDummies.map((d): SelectItem => ({ id: d.name, label: d.name }))
  ), [equipmentDummies]);

  const subCategories = useMemo(() => {
    if (!mainCat) {
      return equipmentDummies.reduce((a, v) => [...a, ...v.subCategories.map((s) => ({ id: s, label: `${v.name} - ${s}` }))], [] as SelectItem[])
        .sort(labelSort);
    }
    return equipmentDummies.filter((e) => e.name === mainCat?.id)
      .reduce((a, v) => [...a, ...v.subCategories.map((s) => ({ id: s, label: `${v.name} - ${s}` }))], [] as SelectItem[])
      .sort(labelSort) || [];
  }, [equipmentDummies, mainCat]);

  const setMainCategory = (cat: SelectItem|undefined) => {
    const newCat = equipmentDummies.find((d) => d.name === cat?.id);
    dispatch(setMainCat({ category: newCat, value: cat }));
  };

  const setSubCategory = (cat: SelectItem|undefined) => {
    const newMainCat = equipmentDummies.find((d) => d.subCategories.some((c) => cat?.label.startsWith(d.name) && c === cat?.id));
    dispatch(setSubCat({ category: newMainCat, value: cat ? { id: cat.id, label: `${cat.id}` } : undefined }));
  };

  const equipmentList: SelectItem[] = useMemo(() => {
    if (!allEquipment) return [];
    return [...allEquipment]
      .sort((a, b) => {
        const aCat = a.subCategoryName === subCat?.label ? `a${a.subCategoryName}` : `b${a.subCategoryName}`;
        const bCat = b.subCategoryName === subCat?.label ? `a${b.subCategoryName}` : `b${b.subCategoryName}`;
        return aCat.localeCompare(bCat, 'nb');
      })
      .map((m) => ({ id: m.internalNumber, label: `${m.internalNumber} - ${m.subCategoryName} - ${m.modelName}` })) as SelectItem[];
  }, [allEquipment]);

  useEffect(() => {
    if (!equipmentList || !equipment) return;
    dispatch(setSelectedEquipment(equipmentList.find((m) => m.id === equipment.internalNumber)));
  }, [equipmentList, equipment]);

  const projectList: SelectItem[] = useMemo(() => {
    if (!projects) return [];
    return [...projects]
      .sort((a, b) => (a.id || Infinity) - (b.id || Infinity))
      .map((p) => ({ id: p.id, label: `${p.id} - ${p.projectName}` }));
  }, [projects]);

  const formValid = useIsFormValid();

  const send = async (
    /** Send and approve order with two separate requests */
    andApprove: boolean = false,
  ) => {
    if (!orderedBy || !project) return;
    setSpinner(true);
    if (mainCat && subCat) {
      const body: NewOrder = {
        from: formatISO(from),
        to: formatISO(to),
        category: mainCat.label,
        subCategory: subCat.label,
        projectId: project.id as number,
        worksite,
        comment,
        ordererEmployeeNumber: orderedBy,
      };
      try {
        await post(body).unwrap();
        onClose(true);
      } catch (e) {
        toast.error('Kunne ikke opprette bestilling');
      }
    } else if (selectedEquipment) {
      const body: MoveEquipmentAssignment = {
        from: formatISO(from),
        to: formatISO(to),
        projectId: project.id as number,
        equipmentInternalNumber: selectedEquipment.id.toString(),
        worksite,
        comment,
        extraEquipmentIds: activeExtraEquipment,
        ordererEmployeeNumber: orderedBy,
      };
      try {
        if (andApprove) {
          await sendAndApprove(body).unwrap();
        } else {
          await createAssignment(body).unwrap();
        }
        onClose(true);
      } catch (e) {
        toast.error('Kunne ikke opprettte tildeling');
      }
    }
    setSpinner(false);
  };

  const isOverlap = useMemo(() => {
    if (overlapMessage === null) return false;
    return overlapMessage.length > 0;
  }, [overlapMessage]);

  const filterOptions = useEquipmentSearchFilter();

  return (
    <div className="edit-task-component">
      <Box sx={{
        display: 'flex', flexDirection: 'column', gap: 2, paddingTop: 1,
      }}
      >
        {isOverlap && (
          <Box className="overlapMessage">
            <span className="title">Utstyr overlapper denne perioden fra: </span>
            {overlapMessage}
          </Box>
        )}
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Box sx={{ flex: 1 }}>
            <DateTimePicker
              closeOnSelect
              format="dd.MM.yyyy"
              disableTime
              size="medium"
              fullWidth
              label="Fra"
              value={from}
              defaultTime={7}
              onChange={(d) => { dispatch(setTo(ensureAfter(to, d))); dispatch(setFrom(d)); }}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <DateTimePicker
              closeOnSelect
              format="dd.MM.yyyy"
              disableTime
              size="medium"
              fullWidth
              label="Til"
              value={to}
              defaultTime={15}
              onChange={(d) => { dispatch(setTo(d)); }}
              minDate={from}
            />
          </Box>
        </Box>
        <Box sx={{ flex: 1 }}>
          <SearchSelect
            label="Prosjekt"
            errorLabel="Velg et prosjekt"
            required
            value={project}
            onChange={(p) => { dispatch(setProject(p)); }}
          >
            {projectList}
          </SearchSelect>
        </Box>
        <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
          { equipment === null ? (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
              <Box sx={{ flex: 1, minWidth: 250 }}>
                <SearchSelect
                  label="Hovedkategori"
                  errorLabel="Velg en kategori"
                  required
                  value={mainCat}
                  onChange={setMainCategory}
                >
                  {mainCategories}
                </SearchSelect>
              </Box>
              <Box sx={{ flex: 1, minWidth: 250 }}>
                <SearchSelect
                  label="Underkategori"
                  errorLabel="Velg en kategori"
                  required
                  value={subCat}
                  onChange={setSubCategory}
                >
                  {subCategories}
                </SearchSelect>
              </Box>
            </Box>
          ) : (
            <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                gap: 1,
              }}
              >
                <SearchSelect
                  label="Utstyr"
                  errorLabel="Velg utstyr"
                  filterOptions={filterOptions}
                  required
                  value={selectedEquipment}
                  onChange={(p) => { dispatch(setSelectedEquipment(p)); }}
                >
                  {equipmentList}
                </SearchSelect>
                {availableExtraEquipment && availableExtraEquipment.length > 0 && (<Typography>Ekstrautstyr</Typography>)}
                {availableExtraEquipment && availableExtraEquipment.map((ee) => (
                  <Checkbox
                    label={`${ee.internalNumber} - ${ee.subCategoryName} - ${ee.modelName} (${ee.dailyRentalPrice} kr/dag)`}
                    checked={activeExtraEquipment.includes(ee.internalNumber)}
                    onChange={toggleExtraEquipment(ee.internalNumber)}
                  />
                ))}
              </Box>
            </Box>
          )}
          <WorkerPicker label="Bestiller" value={orderedBy} onChange={(v) => dispatch(setOrderedBy(v))} />
          <Box sx={{ flex: 1 }}>
            <TextField
              fullWidth
              label="Anlegg - Lokasjon/adresse ved bruk av regnings nr"
              variant="outlined"
              value={worksite || ''}
              onChange={(v) => {
                dispatch(setWorksite(v.target.value));
              }}
            />
          </Box>
          <Box>
            <TextField
              label="Kommentar"
              fullWidth
              multiline
              value={comment || ''}
              onChange={(e) => { dispatch(setComment(e.target.value)); }}
              minRows={4}
            />
          </Box>
        </Box>

        <Box display="flex">
          <Box flex={1} />
          <Box>
            <DataField sx={{ display: totalCost === null ? 'none' : undefined }} label="Totalkostnad">
              {totalCost} kr/dag
            </DataField>
          </Box>
        </Box>

        <Box sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
        >
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button disabled={spinner} color="primary" variant="outlined" onClick={() => onClose(false)}>Avbryt</Button>
            <Button
              variant="contained"
              disabled={!formValid || spinner}
              onClick={() => send()}
            >
              {spinner ? <CircularProgress size={24} /> : 'Send bestilling'}
            </Button>
            {selectedEquipment && (
              <Button
                variant="contained"
                disabled={!formValid || spinner}
                onClick={() => send(true)}
              >
                {spinner ? <CircularProgress size={24} /> : 'Send og godkjenn'}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </div>
  );
};
