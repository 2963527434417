/**
 * Function for determining if arrays are equal
 *
 * Only correctly identifies empty arrays and arrays with the same ref as equal
 */
export const arrayEq = (a: any, b: any) => (
  a && b
  && a.length !== undefined && b.length !== undefined
  && a.length === 0 && b.length === 0
) || a === b;
