import {
  Box,
  Card,
  TextField,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { FC } from 'react';
import { EquipmentAssignment } from '../../../shared/types/equipmentAssignment';

export const OrderCard: FC<{
    assignment: EquipmentAssignment,
}> = ({ assignment }) => {
  const { order, equipment } = assignment;
  const isChangeOrder = order?.type === 'Change';
  const changes = {
    from: order.changes && order.from !== order.changes?.from,
    to: order.changes && order.to !== order.changes?.to,
  };

  const title = isChangeOrder ? 'Endringsbestilling' : 'Bestilling';
  const equipmentName = `${equipment.internalNumber} - ${equipment.subCategoryName} - ${equipment.modelName}`;

  return (
    <Card
      className="original-order-card"
    >
      <Box display="flex" flexDirection="column" gap={2} padding={3}>
        <Typography variant="h6">{title}</Typography>

        {isChangeOrder ? (
          <Box sx={{ display: 'flex', gap: 2 }}>
            <TextField
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
              fullWidth
              label="Utstyr"
              disabled
              value={equipmentName}
            />
          </Box>
        ) : (
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Box sx={{ flex: 1 }}>
              <TextField
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                }}
                fullWidth
                label="Hovedkategori"
                disabled
                value={order?.category}
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <TextField
                variant="standard"
                fullWidth
                label="Underkategori"
                disabled
                value={order?.subCategory}
                InputProps={{
                  disableUnderline: true,
                  sx: { color: 'black' },
                }}
              />
            </Box>
          </Box>
        )}

        <Box sx={{ display: 'flex', gap: 2 }}>
          <Box sx={{ flex: 1 }}>
            <TextField
              variant="standard"
              disabled
              size="medium"
              fullWidth
              label={assignment?.handOutDate ? 'Fra (utlevert)' : 'Fra'}
              value={format(new Date(order?.changes?.from ?? order.from), 'dd.MM.yyyy')}
              InputProps={{
                disableUnderline: true,
              }}
              inputProps={{ className: changes.from ? 'line-through' : '' }}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            {changes.from && (
            <TextField
              variant="standard"
              disabled
              size="medium"
              fullWidth
              label="Endret til"
              value={format(new Date(order.from), 'dd.MM.yyyy')}
              InputProps={{
                disableUnderline: true,
              }}
            />
            )}
          </Box>
          <Box sx={{ flex: 1 }}>
            <TextField
              variant="standard"
              disabled
              size="medium"
              fullWidth
              label="Til"
              value={format(new Date(order?.changes?.to ?? order.to), 'dd.MM.yyyy')}
              InputProps={{
                disableUnderline: true,
              }}
              inputProps={{ className: changes.to ? 'line-through' : '' }}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            {changes.to && (
            <TextField
              variant="standard"
              disabled
              size="medium"
              fullWidth
              label="Endret til"
              value={format(new Date(order?.to), 'dd.MM.yyyy')}
              InputProps={{
                disableUnderline: true,
              }}
            />
            )}
          </Box>
        </Box>
        <Box>
          <TextField
            variant="standard"
            label="Bestillingskommentar"
            InputProps={{
              disableUnderline: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            multiline
            disabled
            value={order?.comment}
            minRows={2}
          />
        </Box>
        <Box>
          <TextField
            variant="standard"
            label="Anlegg - Lokasjon/adresse ved bruk av regnings nr"
            InputProps={{
              disableUnderline: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            multiline
            disabled
            value={order?.worksite}
            minRows={2}
          />
        </Box>
        <Box color="#666666">
          Bestilt
          {' '}
          { order?.createdTimestamp ? format(new Date(order.createdTimestamp), "dd.MM.yyyy 'kl.' HH:mm")
            : order?.createdTimestamp && format(new Date(order.createdTimestamp), "dd.MM.yyyy 'kl.' HH:mm")}
          { order?.createdByName ? ` av ${order.createdByName}`
            : order?.createdByName && ` av ${order.createdByName}` }
          {' '}<br />
          (Sist endret
          {' '}
          { order?.lastEditTime && format(new Date(order.lastEditTime), "dd.MM.yyyy 'kl.' HH:mm")}
          { order?.lastEditedByName && ` av ${order.lastEditedByName})` }
        </Box>

      </Box>
    </Card>
  );
};
