import { EventInput } from '@fullcalendar/core';
import { UNAVAILABILITY_COLOR } from '../../shared/constants';
import { EquipmentUnavailability } from '../../shared/types/equipmentUnavailability';

const COLOR = UNAVAILABILITY_COLOR;

export const eventifyUnavailability = (
  unavailabilities: EquipmentUnavailability[],
  /** Show events as ghosts */
  ghost: boolean = false,
  isAdmin: boolean = false,
): EventInput[] => unavailabilities.map((u) => ({
  id: `${u.id}`,
  title: 'Utilgjengelighet',
  start: new Date(u.from),
  end: new Date(u.to),
  resourceId: u.equipment ? u.equipment.internalNumber : u.equipmentInternalNumber,
  parent: u.equipment ? u.equipment.internalNumber : u.equipmentInternalNumber,
  borderColor: COLOR,
  backgroundColor: ghost ? 'rgba(0,0,0,0)' : COLOR,
  textColor: ghost ? COLOR : 'black',
  resourceEditable: isAdmin,
  durationEditable: isAdmin,
  startEditable: isAdmin,
}));
