import { Page } from '../../shared/components/page';
import { Header, List } from '../../components/HandOutInComponents';
import { PageConstrainer } from '../../components/PageConstrainer';

export const HandOutPage = () => (
  <Page className="hand-out-page">
    <Header page="handOut" />
    <PageConstrainer sx={{ padding: 3, gap: 2 }}>
      <List />
    </PageConstrainer>
  </Page>
);
