import { useMemo } from 'react';
import { Box } from '@mui/material';
import { MultiSearchSelect } from '../../../../shared/components/multiSearchSelect';
import { useDispatch, useSelector } from '../../../../shared/hooks/redux';
import { useGetCategoriesQuery } from '../../../../shared/redux/rental';
import { setSubCategories, useEquipmentOwners } from '../../../../core/redux/leftFilterState';
import { labelSort } from '../../../../shared/logic/functions';

export const SubCategoryFilter = () => {
  const owners = useEquipmentOwners();
  const dispatch = useDispatch();

  const {
    mainCategories: leftMainCat,
    subCategories: leftSubCat,
  } = useSelector((s) => s.leftFilterState.equipment);
  const { data, isLoading } = useGetCategoriesQuery({ includeSubCategories: true, owners });

  const subCategories = useMemo(
    () => Array.from(
      new Set(data
        ?.filter((m) => leftMainCat.length === 0 || leftMainCat.some((mc) => m.name === mc.label))
        ?.flatMap((m) => m.subCategories)),
    ).map((m) => ({ id: m, label: m }))
      .sort(labelSort),
    [data, leftMainCat],
  );

  return (
    <Box minWidth="200px" flex={1}>
      <MultiSearchSelect
        label="Underkategori"
        size="small"
        loading={isLoading}
        value={leftSubCat}
        onChange={(l) => dispatch(setSubCategories(l))}
      >
        {subCategories}
      </MultiSearchSelect>
    </Box>
  );
};
