import {
  FC,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  addHours,
  endOfDay,
  formatISO,
  isBefore,
  isSameDay,
  startOfDay,
  subHours,
} from 'date-fns';
import { toast } from 'react-toastify';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { TaskEvent } from '../../shared/types/util/taskEvent';
import { DateTimePicker } from '../../shared/components/dateTimePicker';
import { SelectItem } from '../../shared/types/util/selectItem';
import { NOT_DELIVERED_COLOR } from '../../shared/constants';
import {
  useDeleteAssignmentMutation,
  useDeleteOrderMutation,
  useGetEquipmentAssignmentQuery,
  useGetCategoriesQuery,
  useGetExtraEquipmentQuery,
  usePostEquipmentOrderMutation,
  usePutApproveAssignmentBodyMutation,
  usePutApproveOrderBodyMutation,
  usePutDeliverAssignmentBodyMutation,
  usePutMoveEquipmentAssignmentMutation,
  usePutMoveEquipmentOrderMutation,
  useGetEquipmentAssignmentsForQuery,
} from '../../shared/redux/rental';
import { NewEquipmentOrder } from '../../shared/types/api/newEquipmentOrder';
import { Displayable } from '../../shared/types/util/displayable';
import { MoveEquipmentOrder } from '../../shared/types/api/moveEquipmentOrder';
import { SearchSelect } from '../../shared/components/searchSelect';
import { Equipment } from '../../shared/types/equipment';
import { MoveEquipmentAssignment } from '../../shared/types/api/moveEquipmentAssignment';
import { PopConfirm } from '../../shared/components/popConfirm';
import { ensureAfter, format } from '../../shared/logic/dates';
import { OrderCard } from './partials/orderCard';
import { EquipmentEditRules } from '../../shared/types/equipmentEditRules';
import { ApproveEquipmentOrder } from '../../shared/types/api/approveEquipmentOrder';
import { Checkbox } from '../../shared/components/checkbox';
import { unique } from '../../core/helpers/functions';
import { ClearableDateTimePicker } from '../clearableDateTimePicker';
import './style.scss';
import { DataField } from '../../shared/components/dataField';
import { Modal } from '../../shared/components/modal';
import { EquipmentOrderStatusIcon } from '../EquipmentOrderStatusIcon';
import { WorkerPicker } from '../../shared/components/workerPicker';
import { getLoggedInRentalUserObject } from '../../shared/hooks/getLoggedInRentalUserObject';
import { RadioGroup } from '../../shared/components/radioGroup';
import { labelSort } from '../../shared/logic/functions';
import { useHasRoles } from '../../core/helpers/useHasRoles';
import { DeliverEquipmentAssignment } from '../../shared/types/api/DeliverEquipmentAssignment';
import { getOrderStatusName } from '../../core/helpers/translate';
import { useOverlapMessage } from '../../shared/hooks/useOverlapMessage';
import { useEquipmentSearchFilter } from '../../shared/hooks/useEquipmentSearchFilter';

export const EquipmentOrderModal: FC<{
  event?: TaskEvent,
  /** Assignment or order */
  assignmentId: string,
  can: EquipmentEditRules,
  projects: Displayable[];
  allEquipment?: Equipment[];
  isEdit?: boolean,
  refetch: () => void,
  open?: boolean,
  onClose?: () => void,
}> = ({
  event,
  assignmentId,
  can,
  projects,
  allEquipment,
  isEdit = false,
  refetch = () => null,
  open = true,
  onClose = () => null,
}) => {
  const { data: equipmentDummies } = useGetCategoriesQuery({ includeSubCategories: true });
  const [updateOrder] = usePutMoveEquipmentOrderMutation();
  const [updateAssignment, { isLoading: assignmentUpdating }] = usePutMoveEquipmentAssignmentMutation();
  const [assignOrder] = usePutApproveOrderBodyMutation();
  const [approveAssignment] = usePutApproveAssignmentBodyMutation();
  const [post] = usePostEquipmentOrderMutation();
  const [deleteOrder] = useDeleteOrderMutation();
  const [deleteAssignment] = useDeleteAssignmentMutation();
  const [deliverAssignment, { isLoading: deliveringAssignment }] = usePutDeliverAssignmentBodyMutation();
  const [spinner, setSpinner] = useState<boolean>(false);
  const [from, setFrom] = useState<Date>(new Date());
  const [to, setTo] = useState<Date>(new Date());
  const [handOutDate, setHandOutDate] = useState<Date | undefined>();
  const [handInDate, setHandInDate] = useState<Date | undefined>();
  const [project, setProject] = useState<SelectItem | undefined>();
  const [equipment, setEquipment] = useState<SelectItem | undefined>();
  const [mainCat, setMainCat] = useState<SelectItem | undefined>();
  const [subCat, setSubCat] = useState<SelectItem | undefined>();
  const [worksite, setWorksite] = useState<string | undefined>();
  const [comment, setComment] = useState<string | undefined>();
  const [deliveryComment, setDeliveryComment] = useState<string | undefined>();
  const [internalComment, setInternalComment] = useState<string | undefined>();
  const [assignEquipment, setAssignEquipment] = useState<boolean>(false);
  const [orderedBy, setOrderedBy] = useState<number | undefined>();
  const [equipmentPlacement, setEquipmentPlacement] = useState<'storage' | 'project' | null>(null);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const { data: extraEquipment } = useGetExtraEquipmentQuery(equipment?.id || skipToken);
  const { data: order } = useGetEquipmentAssignmentQuery(assignmentId || skipToken);

  const { data: allAssignments } = useGetEquipmentAssignmentsForQuery(
    {
      id: equipment?.id || '',
      from: from !== undefined ? formatISO(startOfDay(from)) : undefined,
      to: to !== undefined ? formatISO(endOfDay(to)) : undefined,
    },
    { skip: equipment?.id === undefined },
  );
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [activeExtraEquipment, setActiveExtraEquipment] = useState<string[]>([]);

  const assignmentStatus = useMemo(() => {
    if (!order) return undefined;
    return getOrderStatusName(order);
  }, [order]);

  /**
     * Field is disabled because changing it would create a change order
     */
  const disabledToPreventCO = useMemo(() => (
    (!order?.handInDate !== undefined && handInDate !== undefined) ?? false
  ), [order, /* preventChangeOrder, */ handInDate]);

  const willBecomeCO = useMemo(() => {
    if (order === undefined) return false;

    const extraEquipmentChanged = () => {
      if (!order?.extraEquipment) return false;

      if (order.extraEquipment.length !== activeExtraEquipment.length) return true;
      const tmp = new Set(activeExtraEquipment);
      const tmp2 = order.extraEquipment.map((x) => x.internalNumber);
      const intersect = new Set(tmp2.filter((y) => tmp.has(y)));
      const diff = [...activeExtraEquipment, ...tmp2].filter((y) => !intersect.has(y));
      return diff.length > 0;
    };

    return !isSameDay(new Date(order.from), from)
      || !isSameDay(new Date(order.to), to)
      || order.equipment?.internalNumber !== equipment?.id
      || order.project?.id !== project?.id
      || extraEquipmentChanged();
  }, [order, from, to, equipment, activeExtraEquipment, project]);

  const mainCategories = useMemo(() => (
    equipmentDummies?.map((d): SelectItem => ({ id: d.name, label: d.name })) ?? []
  ), [equipmentDummies]);

  const subCategories = useMemo(() => {
    if (!mainCat) {
      return equipmentDummies?.reduce((a, v) => [...a, ...v.subCategories.map((s) => ({ id: s, label: `${v.name} - ${s}` }))], [] as SelectItem[])
        .sort(labelSort) ?? [];
    }
    return equipmentDummies?.filter((e) => e.name === mainCat?.id)
      .reduce((a, v) => [...a, ...v.subCategories.map((s) => ({ id: s, label: `${v.name} - ${s}` }))], [] as SelectItem[])
      .sort(labelSort) || [];
  }, [mainCat]);

  const setMainCategory = (cat: SelectItem|undefined) => {
    const newCat = equipmentDummies?.find((d) => d.name === cat?.id);
    if (newCat?.subCategories.length === 1) {
      const newSubCat = newCat.subCategories[0];
      setSubCat({ id: newSubCat, label: newSubCat });
    } else if (subCat && !newCat?.subCategories.some((c) => c === subCat.id)) {
      setSubCat(undefined);
    }
    setMainCat(cat);
  };

  const setSubCategory = (cat: SelectItem|undefined) => {
    if (!mainCat) {
      const newMainCat = equipmentDummies?.find((d) => d.subCategories.some((c) => cat?.label.startsWith(d.name) && c === cat?.id));
      if (newMainCat) setMainCat({ id: newMainCat.name, label: newMainCat.name });
    }
    setSubCat(cat ? { id: cat.id, label: `${cat.id}` } : undefined);
  };

  const availableExtraEquipment = useMemo(() => {
    if (!order) return extraEquipment;
    if (!order.extraEquipment) return extraEquipment;
    return unique([...extraEquipment ?? [], ...order.extraEquipment.filter((e) => e.mainInternalNumber === equipment?.id)], 'internalNumber');
  }, [extraEquipment, order, equipment]);

  const totalCost = useMemo(() => {
    let equipmentCost = 0;
    if (equipment !== undefined && allEquipment) {
      /** Equipment */
      const eq = allEquipment.find((ae) => ae.internalNumber === equipment.id);
      if (!eq) return null;
      equipmentCost = eq.dailyRentalPrice;
    } else if (order?.equipment) {
      equipmentCost = order.equipment.dailyRentalPrice || 0;
    } else {
      return null;
    }
    const extraCost = activeExtraEquipment.map((e) => availableExtraEquipment?.find((a) => a.internalNumber === e)?.dailyRentalPrice || 0).reduce((acc, val) => acc + val, 0);
    return equipmentCost + extraCost;
  }, [order, allEquipment, equipment, activeExtraEquipment, availableExtraEquipment]);

  useEffect(() => {
    const alreadyActive = order && order.extraEquipment ? order.extraEquipment.map((e) => e.internalNumber) : [];
    setActiveExtraEquipment(unique([...activeExtraEquipment, ...alreadyActive].filter((e) => availableExtraEquipment?.some((a) => a.internalNumber === e))));
  }, [availableExtraEquipment, order]);

  const toggleExtraEquipment = (id: string) => () => {
    if (activeExtraEquipment.includes(id)) {
      setActiveExtraEquipment(activeExtraEquipment.filter((a) => a !== id));
    } else {
      setActiveExtraEquipment([...activeExtraEquipment, id]);
    }
    setIsChanged(true);
  };

  const equipmentList = useMemo(() => {
    if (!allEquipment) return [];
    const items = allEquipment
      .filter((m) => !mainCat || m.mainCategoryName === mainCat.label)
      .sort((a, b) => {
        const aCat = a.subCategoryName === subCat?.label ? `a${a.subCategoryName}` : `b${a.subCategoryName}`;
        const bCat = b.subCategoryName === subCat?.label ? `a${b.subCategoryName}` : `b${b.subCategoryName}`;
        return aCat.localeCompare(bCat, 'nb');
      })
      .map((m) => ({ id: m.internalNumber, label: `${m.internalNumber} - ${m.subCategoryName} - ${m.modelName}` })) as SelectItem[];
    return items;
  }, [allEquipment, mainCat, subCat]);

  const loggedInUser = getLoggedInRentalUserObject();

  useEffect(() => {
    if (loggedInUser === null) return;
    if (isEdit) {
      if (!order) return;
      setOrderedBy(order.ordererEmployeeNumber || loggedInUser?.employeeNumber);
    } else {
      setOrderedBy(loggedInUser?.employeeNumber);
    }
  }, [order, loggedInUser]);

  useEffect(() => {
    if (isEdit) {
      if (!order) return;
      setFrom(new Date(order.from));
      setTo(new Date(order.to));
      setProject({ id: order.project.id, label: `${order.project.id} - ${order.project.projectName}` });
      setEquipment(order.equipment
        ? {
          id: order.equipment.internalNumber,
          label: `${order.equipment.internalNumber} - ${order.equipment.subCategoryName} - ${order.equipment.modelName}`,
        }
        : undefined);
      setMainCat({ id: order.category, label: order.category });
      setSubCat({ id: order.subCategory, label: order.subCategory });
      setWorksite(order.worksite ?? order.order?.worksite);
      setComment(order.comment ?? order.order?.comment);
      setComment(order.comment ?? order.order?.comment);
      setHandOutDate(order?.handOutDate ? new Date(order?.handOutDate) : undefined);
      setHandInDate(order?.handInDate ? new Date(order?.handInDate) : undefined);
      setInternalComment(order.type === 'Assignment' ? order.comment : undefined);
      setDeliveryComment(order.returnComment);
      if (order.status === 'Delivered') {
        setEquipmentPlacement(order.returnProjectId ? 'project' : 'storage');
      }
    } else {
      if (!event) return;
      if (subCategories.some((s) => s.label === event.title)) {
        setSubCat({ id: event.title || event.id, label: event.title || event.id });
      }
      const defaultProject = { id: event.parent, label: `${event.parent} - ${projects.find((p) => p.id === event.parent)?.label}` };
      setFrom(addHours(event.startTime, 7));
      setTo(subHours(event.endTime, 9));
      setProject(defaultProject);
      setWorksite(undefined);
      setComment(undefined);
      setIsChanged(true);
    }
  }, [order, event, isEdit]);

  const projectList: SelectItem[] = projects.map((p) => ({ id: p.id, label: `${p.id} - ${p.label}` }));

  const buttonConfirmText = useMemo(() => {
    if (assignEquipment) return 'Tildel';
    if (isEdit) return 'Lagre';
    return 'Send bestilling';
  }, [assignEquipment, can.seeApproveEquipmentButton]);

  const buttonModalStateText = useMemo(() => {
    if (can.seeApproveEquipmentButton) return 'Godkjenn bestilling';
    if (assignEquipment) return 'Avbryt tildeling';
    return 'Tildel utstyr';
  }, [assignEquipment, can.seeApproveEquipmentButton]);

  const periodValid = useMemo(() => {
    if (isBefore(from, to)) return true;
    return false;
  }, [from, to]);

  const formValid = useMemo(() => {
    if (isEdit) {
      if (can.seeAssignEquipmentButton && assignEquipment && !equipment) return false;
      if (can.seeApproveEquipmentButton && !equipment) return false;
      if (!order?.handInDate && handInDate && !equipmentPlacement) return false;
      if (!order) return false;
    } else if (!event) {
      return false;
    }
    if (!orderedBy || !periodValid || !project || !mainCat || !subCat) return false;
    return true;
  }, [
    event,
    order,
    isEdit,
    orderedBy,
    periodValid,
    project,
    mainCat,
    subCat,
    can.seeAssignEquipmentButton,
    can.seeApproveEquipmentButton,
    assignEquipment,
    equipment,
    handInDate,
    equipmentPlacement,
  ]);

  const moveToProjectId = useMemo(() => {
    if (!project) return undefined;
    if (equipmentPlacement === 'storage') return null;
    if (equipmentPlacement === 'project') return project.id as number;
    return undefined;
  }, [project, equipmentPlacement]);

  const approve = () => {
    setSpinner(true);
    if (!isEdit || !order || !equipment || !project || !orderedBy) return;
    const body: MoveEquipmentAssignment = {
      from: formatISO(from),
      to: formatISO(to),
      equipmentInternalNumber: `${equipment.id}`,
      projectId: project.id as number,
      worksite: worksite || '',
      comment: internalComment || '',
      handInDate: handInDate ? formatISO(handInDate) : undefined,
      handOutDate: handOutDate ? formatISO(handOutDate) : undefined,
      extraEquipmentIds: activeExtraEquipment,
      ordererEmployeeNumber: orderedBy,
    };
    approveAssignment(({ id: order.id, body })).unwrap().then(() => {
      setSpinner(false);
      onClose();
    }).catch(() => {
      toast.error('Kunne ikke godkjenne bestilling');
      setSpinner(false);
    });
  };

  const assign = (autoApprove: boolean = false) => {
    if (!isEdit || !order || !equipment || !from || !to || !orderedBy) return;
    const body: ApproveEquipmentOrder = {
      from: formatISO(from),
      to: formatISO(to),
      projectId: order.project.id,
      equipmentInternalNumber: `${equipment?.id}`,
      comment: '',
      extraEquipment: activeExtraEquipment,
      ordererEmployeeNumber: orderedBy,
      status: autoApprove ? 'Approved' : undefined,
    };
    assignOrder({ id: order.id, body }).unwrap().then(() => {
      refetch();
      setSpinner(false);
      onClose();
    }).catch(() => {
      toast.error('Kunne ikke tildele utstyr');
      setSpinner(false);
    });
  };

  const sendDeliveryRequest = (id: string, body: DeliverEquipmentAssignment) => {
    deliverAssignment({ id, body }).unwrap().then(() => {
      refetch();
      setSpinner(false);
      onClose();
    }).catch(() => {
      toast.error('Kunne ikke markere som ledig');
      setSpinner(false);
    });
  };

  const deliver = () => {
    if (!isEdit || !order || !handInDate || moveToProjectId === undefined) return;
    const body: DeliverEquipmentAssignment = {
      handInDate: formatISO(handInDate),
      moveToProject: moveToProjectId,
      comment: deliveryComment,
    };
    sendDeliveryRequest(order.id, body);
  };

  const cancelDelivery = () => {
    if (!isEdit || !order) return;
    const body: DeliverEquipmentAssignment = {
      handInDate: null,
      moveToProject: null,
    };
    sendDeliveryRequest(order.id, body);
  };

  const assignmentUpdate = (confirmDelivery: boolean = false) => {
    if (!isEdit || !order || !equipment || !project || !orderedBy) return;
    if (!order?.handInDate && handInDate && moveToProjectId === undefined) return;
    const body: MoveEquipmentAssignment = {
      from: formatISO(from),
      to: formatISO(to),
      equipmentInternalNumber: `${equipment.id}`,
      projectId: project.id as number,
      worksite: worksite || '',
      comment: internalComment || '',
      handInDate: handInDate ? formatISO(handInDate) : undefined,
      handOutDate: handOutDate ? formatISO(handOutDate) : undefined,
      extraEquipmentIds: activeExtraEquipment,
      ordererEmployeeNumber: orderedBy,
      moveToProject: moveToProjectId,
      confirmDelivery,
    };
    updateAssignment(({ id: order.id, body })).unwrap().then(() => {
      refetch();
      setSpinner(false);
      onClose();
    }).catch(() => {
      toast.error('Kunne ikke oppdatere bestilling');
      setSpinner(false);
    });
  };

  const deliveryInfoChanged = useMemo(() => {
    if (moveToProjectId || undefined !== order?.returnProjectId) return true;

    if (deliveryComment !== order?.returnComment) return true;

    if (!handInDate && !order?.handInDate) return false;
    if (!handInDate || !order?.handInDate) return true;
    if (!isSameDay(handInDate, new Date(order.handInDate))) return true;

    return false;
  }, [order, handInDate, moveToProjectId, deliveryComment]);

  const cancelStrings = useMemo(() => {
    switch (can.cancelButtonText) {
      case 'Kansellert': return { success: 'kansellert', error: 'kansellere' };
      case 'Slett': return { success: 'slettet', error: 'slette' };
      default: return { success: 'avslått', error: 'avså' };
    }
  }, [can]);

  const assignmentDelete = () => {
    if (!order) return;
    setSpinner(true);

    deleteAssignment(order.id).unwrap().then(() => {
      refetch();
      onClose();
    }).catch(() => {
      toast.error(`Kunne ikke ${cancelStrings.error} bestilling`);
    })
      .finally(() => setSpinner(false));
  };

  const orderUpdate = () => {
    if (!isEdit || !order || !project || !mainCat || !subCat || !subCat || !orderedBy) return;
    const body: MoveEquipmentOrder = {
      from: formatISO(from),
      to: formatISO(to),
      category: mainCat.label,
      subCategory: subCat?.id as string,
      projectId: project.id as number,
      worksite: worksite || '',
      comment: comment || '',
      ordererEmployeeNumber: orderedBy,
    };
    updateOrder(({ id: order.id, body })).unwrap().then(() => {
      refetch();
      setSpinner(false);
      onClose();
    }).catch(() => {
      toast.error('Kunne ikke oppdatere bestilling');
      setSpinner(false);
    });
  };

  const orderDelete = () => {
    if (!order) return;
    setSpinner(true);
    deleteOrder(order.id).unwrap().then(() => {
      refetch();
      onClose();
    }).catch(() => {
      toast.error(`Kunne ikke ${cancelStrings.success} bestilling`);
    })
      .finally(() => setSpinner(false));
  };

  const send = () => {
    if (isEdit || !event || !mainCat || !subCat || !subCat || !orderedBy) return;
    const body: NewEquipmentOrder = {
      from: formatISO(from),
      to: formatISO(to),
      category: mainCat.label,
      subCategory: subCat.label,
      projectId: event.parent as number,
      worksite,
      comment,
      ordererEmployeeNumber: orderedBy,
    };
    post(body).unwrap().then(() => {
      refetch();
      setSpinner(false);
      onClose();
    }).catch(() => {
      setSpinner(false);
      toast.error('Kunne ikke opprette bestilling');
    });
  };

  const confirmHandler = (v: string) => {
    setSpinner(true);
    if (isEdit) {
      if (v === 'Lagre' && order?.type === 'Assignment') return assignmentUpdate();
      if (v === 'Lagre' && order?.type === 'Order') return orderUpdate();

      if (v === 'Tildel') return assign();

      if (order?.type === 'Assignment') return assignmentUpdate();
    }
    return send();
  };

  const overlapMessage = useOverlapMessage(
    allAssignments,
    order,
    equipment,
    from,
    to,
    handOutDate,
    handInDate,
  );

  const isOverlap = useMemo(() => {
    if (overlapMessage === null) return false;
    return overlapMessage.length > 0;
  }, [overlapMessage]);

  const checkClose = () => {
    if (isChanged) {
      setConfirmModalOpen(true);
    } else {
      onClose();
    }
  };

  const isCoordinator = useHasRoles('internutleie-koordinator');
  const isBestiller = useHasRoles('bestiller') && !isCoordinator;

  const confirmButton = can.seeSaveButton && (
  <Button
    variant="contained"
    value={buttonConfirmText}
    disabled={!formValid || spinner || !isChanged}
    onClick={(e) => {
      setConfirmModalOpen(false);
      confirmHandler(e.currentTarget.value);
    }}
  >
    {spinner ? <CircularProgress size={24} /> : buttonConfirmText}
  </Button>
  );

  const projectEndDate = useMemo(() => {
    const endDate = projects.find((p) => p.id === project?.id)?.endDate
    || allAssignments?.find((a) => a.project.id === project?.id)?.project?.endDate;
    return (
      <Box component="span" color={endDate && new Date(endDate) < new Date() ? NOT_DELIVERED_COLOR : ''}>
        {endDate ? format(endDate, 'dd.MM.yyyy') : '?'}
      </Box>
    );
  }, [project, projects, allAssignments]);

  const filterOptions = useEquipmentSearchFilter();

  return (
    <>
      <Modal
        open={open}
        title={
        `${order?.type === 'Assignment'
          ? 'Utstyrtildeling'
          : 'Utstyrbestilling'} - ${order?.project?.projectName}`
      }
        compactHeader
        statusIcon={order && <EquipmentOrderStatusIcon a={order} />}
        onClose={() => checkClose()}
        footer={(
          <Box display="flex" flexDirection="column" flex={1} gap={1.5} padding="0px 8px 6px 8px">
            <Box display="flex" justifyContent="space-between">
              <Box color="#666666" display="flex" flexDirection="column">
                {can.seeLastEditBy && (
                <span>
                  Sist endret
                    {' '}
                  {order?.lastEditTime ? format(new Date(order.lastEditTime), "dd.MM.yyyy 'kl.' HH:mm")
                    : order?.createdTimestamp && format(new Date(order.createdTimestamp), "dd.MM.yyyy 'kl.' HH:mm")}
                  {order?.lastEditedByName ? ` av ${order.lastEditedByName}`
                    : order?.createdByName && ` av ${order.createdByName}`}
                </span>
                )}
                {can.seeApprovedBy && (
                <span>
                  Godkjent
                    {' '}
                  {order?.approvedTime ? format(new Date(order.approvedTime), "dd.MM.yyyy 'kl.' HH:mm")
                    : order?.lastEditTime && format(new Date(order.lastEditTime), "dd.MM.yyyy 'kl.' HH:mm")}
                  {order?.approvedByName ? ` av ${order.approvedByName}`
                    : order?.lastEditedByName && ` av ${order.lastEditedByName}`}
                </span>
                )}
              </Box>
              <Box>
                <DataField sx={{ display: totalCost === null ? 'none' : undefined }} label="Totalkostnad">
                  {totalCost} kr/dag
                </DataField>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: (can.seeAssignEquipmentButton || can.seeApproveEquipmentButton) || can.seeCancelButton
                  ? 'space-between'
                  : 'flex-end',
              }}
            >
              <Box display="flex" gap={2}>
                {(can.seeAssignEquipmentButton || can.seeApproveEquipmentButton) && (
                <Button
                  variant="contained"
                  color={can.seeApproveEquipmentButton ? 'primary' : 'info'}
                  disabled={spinner || !formValid}
                  onClick={() => (can.seeApproveEquipmentButton ? approve() : setAssignEquipment(!assignEquipment))}
                  sx={{ minWidth: spinner && can.seeApproveEquipmentButton ? '200px' : null }}
                >
                  {spinner && can.seeApproveEquipmentButton ? <CircularProgress size={24} /> : buttonModalStateText}
                </Button>
                )}
                {can.seeCancelButton && !handInDate && (
                <PopConfirm
                  confirmText={can.cancelButtonText}
                  buttonText={can.cancelButtonText}
                  buttonColor="primary"
                  buttonVariant="outlined"
                  buttonProps={{ disabled: spinner }}
                  content={(
                    <>
                      Vil du {can.cancelButtonText} denne oppgaven?<br />
                      Det vil ikke være mulig å angre
                    </>
                    )}
                  onConfirm={order?.type === 'Order' ? orderDelete : assignmentDelete}
                />
                )}
              </Box>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Button disabled={spinner} color="primary" variant="outlined" onClick={onClose}>{can.closeButtonText}</Button>
                {confirmButton}
                {can.seeSaveButton && assignEquipment && (
                <Button
                  variant="contained"
                  value={buttonConfirmText}
                  disabled={!formValid || spinner || !isChanged}
                  onClick={() => assign(true)}
                >
                  {spinner ? <CircularProgress size={24} /> : `${buttonConfirmText} og Godkjenn`}
                </Button>
                )}
              </Box>
            </Box>
          </Box>
        )}
      >
        <div className="edit-task-component">
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            paddingTop: 1,
          }}
          >
            {isOverlap && order?.type !== 'Order' && order?.order && (
            <Box className="overlapMessage">
              <span className="title">Utstyr overlapper denne perioden fra: </span>
              {overlapMessage}
            </Box>
            )}
            {can.seeOrderCard && order?.order && (
            <OrderCard assignment={order} />
            )}

            <Box sx={{ display: 'flex', gap: 2 }}>
              <Box sx={{ flex: 1 }}>
                <DateTimePicker
                  format="dd.MM.yyyy"
                  disableTime
                  disabled={!!handOutDate || !can.editTime || disabledToPreventCO}
                  size="medium"
                  fullWidth
                  label="Fra"
                  value={from}
                  onChange={(d) => { setTo(ensureAfter(to, d)); setFrom(d); setIsChanged(true); }}
                  closeOnSelect
                />
              </Box>
              <Box sx={{ flex: 1 }}>
                <DateTimePicker
                  format="dd.MM.yyyy"
                  disableTime
                  disabled={!!handInDate || !can.editTime || disabledToPreventCO}
                  size="medium"
                  fullWidth
                  label="Til"
                  value={to}
                  onChange={(d) => { setTo(d); setIsChanged(true); }}
                  minDate={from}
                  closeOnSelect
                />
              </Box>
            </Box>
            {(order?.status === 'Approved' || order?.status === 'Delivered' || order?.order?.type === 'Change') && (
            <Box sx={{ display: 'flex', gap: 2 }}>
              {/* <ClearableDateTimePicker
                btnTxt="Utlever utstyr"
                label="Utlevert"
                disabled={!can.editHandOut}
                disableClear={!!handInDate}
                date={handOutDate}
                  // eslint-disable-next-line
                  onChange={(d) => { (!!handInDate && !!d) && setHandInDate(ensureAfter(handInDate, d)); setHandOutDate(d); setIsChanged(true); }}
              /> */}
              {(!order?.handInDate || order.status === 'Delivered') && handInDate && (
              <DataField label="Plassering" sx={{ flex: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <RadioGroup
                    value={equipmentPlacement}
                    onChange={(v) => {
                      setEquipmentPlacement(v);
                      setIsChanged(true);
                    }}
                    sx={{ flexDirection: 'row' }}
                    options={[
                      { id: 'storage', label: 'Inne' },
                      { id: 'project', label: `Ute (${project?.id})` },
                    ]}
                  />
                  {!equipmentPlacement && (
                  <Typography sx={{ color: 'red' }}>Velg plassering</Typography>
                  )}
                </Box>
              </DataField>
              )}
              <ClearableDateTimePicker
                btnTxt={isBestiller ? 'Marker som ledig' : 'Innlever utstyr'}
                label="Innlevert"
                disabled={/* !handOutDate || */ !can.editHandIn || willBecomeCO}
                date={handInDate}
                // minDate={handOutDate}
                minDate={from}
                disableClear={isBestiller && !!order?.handInDate}
                onChange={(d) => {
                  if (d) setHandOutDate(from);
                  else setHandOutDate(undefined);
                  setHandInDate(d);
                  setIsChanged(true);
                }}
              />
            </Box>
            )}
            {/*
              handInDate = if value is set (if button has been pressed)
              bestiller should only see it if it is not finished, aka if it is not handed in and approved
              coordinator should only see it if it is delivered
            */}
            {handInDate && ((isBestiller && assignmentStatus !== 'completed') || (isCoordinator && order?.status === 'Delivered')) && (
            <Box sx={{ flex: 1 }}>
              <TextField
                multiline
                fullWidth
                rows={3}
                label="Leveringskommentar"
                value={deliveryComment}
                disabled={!isBestiller}
                onChange={(e) => setDeliveryComment(e.target.value)}
              />
              <Typography sx={{ fontStyle: 'italic', color: '#666', fontSize: 14 }}>
                henteadresse, hentetidspunkt, frister eller annen informasjon
                til den som skal hente eller mottar utstyr
              </Typography>
            </Box>
            )}
            {handInDate && isBestiller && assignmentStatus !== 'completed' && (
            <Box sx={{ flex: 1, display: 'flex', gap: 2 }}>
              {handInDate && isBestiller && assignmentStatus === 'delivered' && (
              <Button
                fullWidth
                variant="contained"
                color="error"
                onClick={deliveringAssignment ? undefined : cancelDelivery}
              >
                {deliveringAssignment && <CircularProgress size={24} color="secondary" /> }
                {!deliveringAssignment && 'Angre innlevering'}
              </Button>
              )}
              <Button
                fullWidth
                variant="contained"
                disabled={!equipmentPlacement || !deliveryInfoChanged}
                onClick={deliveringAssignment ? undefined : deliver}
              >
                {deliveringAssignment && <CircularProgress size={24} color="secondary" /> }
                {!deliveringAssignment && !order?.handInDate && 'Lagre'}
                {!deliveringAssignment && order?.handInDate && 'Oppdater levering'}
              </Button>
            </Box>
            )}
            {handInDate && isCoordinator && assignmentStatus === 'delivered' && (
            <Box sx={{ flex: 1, display: 'flex', gap: 2 }}>
              <Tooltip title="Godkjenn leveringstidspunkt og plassering som bestiller la inn">
                <Button
                  fullWidth
                  variant="contained"
                  disabled={!equipmentPlacement || !formValid}
                  onClick={assignmentUpdating ? undefined : () => assignmentUpdate(true)}
                >
                  {assignmentUpdating && <CircularProgress size={24} color="secondary" /> }
                  {!assignmentUpdating && 'Godkjenn innlevering'}
                </Button>
              </Tooltip>
            </Box>
            )}
            <Box sx={{ flex: 1 }}>
              <SearchSelect
                label="Prosjekt"
                errorLabel="Velg et prosjekt"
                disabled={
                  !can.editProject
                  || disabledToPreventCO
                  || order?.status === 'Approved'
                  || order?.order.type === 'Change'
                }
                required
                value={project}
                onChange={(p) => { setProject(p); setIsChanged(true); }}
              >
                {projectList}
              </SearchSelect>
              {project && (
              <Box
                sx={{
                  marginTop: 0.3,
                  marginLeft: 0.5,
                  fontSize: 14,
                }}
              >
                <span>Slutt dato: </span>
                {projectEndDate}
              </Box>
              )}
            </Box>
            <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
              <Box sx={{ display: 'flex', gap: 2 }}>
                {can.seeMainCat && (
                <Box sx={{ flex: 1 }}>
                  <SearchSelect
                    label="Hovedkategori"
                    errorLabel="Velg en kategori"
                    required
                    disabled={!can.editSubCat}
                    value={mainCat}
                    onChange={(c) => { setMainCategory(c); setIsChanged(true); }}
                  >
                    {mainCategories}
                  </SearchSelect>
                </Box>
                )}
                {can.seeSubCat && (
                <Box sx={{ flex: 1 }}>
                  <SearchSelect
                    label="Underkategori"
                    errorLabel="Velg en kategori"
                    required
                    disabled={!can.editSubCat}
                    value={subCat}
                    onChange={(c) => {
                      setSubCategory(c);
                      setEquipment(undefined);
                      setActiveExtraEquipment([]); setIsChanged(true);
                    }}
                  >
                    {subCategories}
                  </SearchSelect>
                </Box>
                )}
              </Box>
              <WorkerPicker
                label="Bestiller"
                value={orderedBy}
                disabled={!can.editOrderer}
                onChange={(x) => { setOrderedBy(x); setIsChanged(true); }}
              />
              <Box sx={{ flex: 1 }}>
                <TextField
                  fullWidth
                  label="Anlegg - Lokasjon/adresse ved bruk av regnings nr"
                  variant="outlined"
                  value={worksite || ''}
                  disabled={!can.editWorksite}
                  onChange={(v) => {
                    setWorksite(v.target.value);
                    setIsChanged(true);
                  }}
                />
              </Box>
              {(order?.type === 'Order' ? assignEquipment : can.seeEquipment) && (
              <Box>
                <Box sx={{ flex: 1 }}>
                  <SearchSelect
                    label="Tildelt utstyr"
                    errorLabel="Velg utstyr"
                    disabled={!can.editEquipment || disabledToPreventCO}
                    required
                    filterOptions={filterOptions}
                    value={equipment}
                    onChange={(p) => {
                      if (p?.id !== equipment?.id) {
                        setActiveExtraEquipment([]);
                      }
                      setEquipment(p);
                      setIsChanged(true);
                    }}
                  >
                    {equipmentList}
                  </SearchSelect>
                </Box>
                <Box>
                  {availableExtraEquipment && availableExtraEquipment.length > 0 && (<Typography>Ekstrautstyr</Typography>)}
                  {availableExtraEquipment?.map((ee) => (
                    <Checkbox
                      key={ee.internalNumber}
                      label={`${ee.internalNumber} - ${ee.subCategoryName} - ${ee.modelName} (${ee.dailyRentalPrice} kr/dag)`}
                      checked={activeExtraEquipment.includes(ee.internalNumber)}
                      onChange={toggleExtraEquipment(ee.internalNumber)}
                      disabled={disabledToPreventCO}
                    />
                  ))}
                </Box>
              </Box>
              )}
              {isOverlap && order?.type === 'Order' && (
              <Box className="overlapMessage">
                <span className="title">Utstyr overlapper denne perioden fra: </span>
                {overlapMessage}
              </Box>
              )}
              {can.seeComment && (
              <Box>
                <TextField
                  label="Kommentar"
                  fullWidth
                  multiline
                  disabled={!can.editComment}
                  value={comment || ''}
                  onChange={(e) => { setComment(e.target.value); setIsChanged(true); }}
                  minRows={4}
                />
              </Box>
              )}
            </Box>
            {can.seeInternalComment && order?.order && (
            <Box>
              <TextField
                label="Internkommentar"
                fullWidth
                multiline
                disabled={!can.editInternalComment}
                value={internalComment || ''}
                onChange={(e) => { setInternalComment(e.target.value); setIsChanged(true); }}
                minRows={3}
              />
            </Box>
            )}
            {!periodValid && (
            <Typography color={NOT_DELIVERED_COLOR} align="right">Fra tid må være før til tid</Typography>
            )}
          </Box>
        </div>
      </Modal>
      <Modal
        open={confirmModalOpen}
        title="Vil du lagre endringene dine?"
        maxWidth="xs"
        onClose={() => { setConfirmModalOpen(false); }}
      >
        <Box display="flex" flex={1} gap={1}>
          <Box flex={1}>
            <Tooltip title="Lukk tildelingen uten å lagre endringene">
              <Button variant="contained" color="error" onClick={() => { setConfirmModalOpen(false); onClose(); }}>Forkast</Button>
            </Tooltip>
          </Box>
          <Tooltip title="Tilbake til redigering">
            <Button variant="outlined" color="primary" onClick={() => { setConfirmModalOpen(false); }}>Tilbake</Button>
          </Tooltip>
          {confirmButton}
        </Box>
      </Modal>
    </>
  );
};
