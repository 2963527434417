import {
  Box,
  Checkbox,
  SxProps,
  Typography,
} from '@mui/material';

const textSx: SxProps = {
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  flex: 1,
  width: '0px',
};

export type CheckedStatus = 'none' | 'some' | 'all';

export const ListHeader = ({
  checked = 'none',
  onChange = () => null,
}: {
  /** False for unchecked, true for checked, and undefined for semi-checked */
  checked?: CheckedStatus,
  onChange?: (v: boolean) => void,
}) => {
  const checkboxClicked = () => {
    onChange(checked !== 'all');
  };
  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      gap: 2,
      borderBottom: '1px solid #777',
    }}
    >
      <Checkbox
        checked={checked === 'all'}
        indeterminate={checked === 'some'}
        onChange={checkboxClicked}
      />
      <Box width={24} />
      <Typography sx={{ fontWeight: 'bold', width: 100 }}>Internnr</Typography>
      <Typography sx={{ ...textSx, flex: 1 }}>kategori / model</Typography>
      <Typography sx={{ ...textSx, flex: 1 }}>Fra prosjekt</Typography>
    </Box>
  );
};
