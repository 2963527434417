import {
  FC,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  ButtonGroup,
  Button,
  IconButton,
  FormControlLabel,
  Checkbox,
  Box,
} from '@mui/material';
import {
  addDays,
  addMonths,
  addWeeks,
  isSameDay,
  startOfDay,
} from 'date-fns';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { CalendarView, view } from '../../../../core/types/util/calendarView';
import { format, getWeek } from '../../../../shared/logic/dates';
import { PopMenu } from '../../../../shared/components/popMenu';
import { PopMenuHeader } from '../../../../shared/components/popMenuHeader';
import {
  setOnlyEventOverlap,
  setOnlyAssignmentsWithExpiredProject,
  setShowAllOrders,
  setWeekend,
} from '../../../../core/redux/viewSetting';
import { useHasRoles } from '../../../../core/helpers/useHasRoles';
import { ProjectFilter } from './projectFilter';
import { useDispatch, useSelector } from '../../../../shared/hooks/redux';
import { Modal } from '../../../../shared/components/modal';
import { NewEquipmentOrderModal } from '../../../../components/newEquipmentOrderModal';
import { AssignmentColorFilter } from './assignmentColorFilter';
import { MainCategoryFilter } from './mainCategoryFIlter';
import { SubCategoryFilter } from './subCategoryFilter';
import { FilterIcon } from '../../../../shared/components/filterIcon';

export const TimelineHeader: FC<{
  day?: Date,
  currentView?: CalendarView,
  onDayChange?: (date: Date) => void,
  onCalendarViewChange?: (view: CalendarView) => void,
  filters?: ReactNode,
}> = ({
  day = new Date(),
  currentView = 'month',
  onDayChange = () => null,
  onCalendarViewChange = () => null,
  filters,
}) => {
  const [pickerVal, setPickerVal] = useState<Date>(day);
  const [addModalOpen, setAddModalOpen] = useState<boolean>(false);
  const showWeekend = useSelector((s) => s.viewSetting.showWeekend);
  const showAllOrders = useSelector((s) => s.viewSetting.showAllOrders);
  const showOnlyAssignmentWithExpiredProject = useSelector((s) => s.viewSetting.showOnlyAssignmentsWithExpiredProject);
  const showOnlyEventOverlap = useSelector((s) => s.viewSetting.showOnlyEventOverlap);
  const sameDay = useMemo(() => (format(day, 'P') === format(new Date(), 'P')), [day]);
  const dispatch = useDispatch();
  const canCreateOrder = useHasRoles(['bestiller', 'internutleie-koordinator']);

  useEffect(() => {
    if (!isSameDay(pickerVal, day)) {
      setPickerVal(day);
    }
  }, [day]);

  const viewPeriod = useMemo(() => {
    if (currentView === 'maned') return (date: Date, dir: boolean) => startOfDay(addWeeks(date, dir ? 1 : -1));
    if (currentView === 'kvartal') return (date: Date, dir: boolean) => startOfDay(addMonths(date, dir ? 1 : -1));
    if (currentView === 'ar') return (date: Date, dir: boolean) => startOfDay(addMonths(date, dir ? 1 : -1));
    return (date: Date, dir: boolean) => startOfDay(addDays(date, dir ? 1 : -1));
  }, [currentView]);

  const dateFormat = useMemo(() => {
    if (currentView === 'maned') return (date: Date) => `uke ${getWeek(date)} ${format(date, 'yyyy')}`;
    if (currentView === 'kvartal') return (date: Date) => format(date, 'MMMM yyyy');
    if (currentView === 'ar') return (date: Date) => format(date, 'MMMM yyyy');
    return (date: Date) => format(date, 'dd. MMMM yyyy');
  }, [currentView]);

  return (
    <div className="timeline-header-component">
      <Box
        sx={{
          flex: 1,
          display: 'grid',
          gridTemplateAreas: '"left center right"',
          gridTemplateColumns: '1fr 3fr auto',
          gridGap: '8px',
          '@media (max-width: 1700px)': {
            gridTemplateAreas: '"left right" "center center"',
            gridTemplateColumns: '1fr auto',
          },
        }}
      >
        <Box className="align-left" gridArea="left">
          <ButtonGroup>
            <Button variant="contained" onClick={() => onDayChange(viewPeriod(day, false))}><ChevronLeft /></Button>
            <Button variant="contained" onClick={() => onDayChange(viewPeriod(day, true))}><ChevronRight /></Button>
          </ButtonGroup>
          <ButtonGroup>
            <Button variant="contained" sx={{ whiteSpace: 'nowrap' }} disabled={sameDay} onClick={() => onDayChange(new Date())}>I dag</Button>
          </ButtonGroup>
          <MobileDatePicker
            value={pickerVal}
            onChange={(v) => setPickerVal(v || day)}
            inputFormat="yyyy-MM-dd'T'HH:mm:ss"
            label="Velg dato"
            onAccept={(newValue) => {
              if (newValue) onDayChange(startOfDay(newValue));
            }}
            renderInput={({ inputRef, inputProps }) => (
              <input
                className="date-text"
                readOnly
                id="datePicker"
                ref={inputRef}
                value={dateFormat(new Date(inputProps?.value))}
                onClick={inputProps?.onClick}
              />
            )}
          />
        </Box>
        <Box display="flex" gap={2} gridArea="center">
          <MainCategoryFilter />
          <SubCategoryFilter />
          <AssignmentColorFilter />
          <ProjectFilter />
        </Box>
        <Box display="flex" gap={1} alignItems="center" gridArea="right">
          {canCreateOrder && (
          <Button sx={{ whiteSpace: 'nowrap' }} onClick={() => setAddModalOpen(true)}>Ny bestilling</Button>
          )}
          <PopMenu
            button={(
              <IconButton color="primary" aria-label="showWeekend" component="span">
                <FilterIcon filterActive={showWeekend || showAllOrders || showOnlyAssignmentWithExpiredProject || showOnlyEventOverlap} />
              </IconButton>
          )}
            width={filters === undefined ? '200px' : '300px'}
          >
            <span>
              {filters !== undefined && (
              <span>
                <PopMenuHeader>Filter</PopMenuHeader>
                {filters}
              </span>
              ) }
              <span>
                <PopMenuHeader>Visning</PopMenuHeader>
                <Box display="flex" flexDirection="column" gap={1}>
                  <FormControlLabel
                    label="Vis helg"
                    control={(
                      <Checkbox
                        checked={showWeekend}
                        onChange={() => dispatch(setWeekend(!showWeekend))}
                      />
        )}
                  />
                  <FormControlLabel
                    label="Vis alle bestillinger"
                    control={(
                      <Checkbox
                        checked={showAllOrders}
                        onChange={() => dispatch(setShowAllOrders(!showAllOrders))}
                      />
        )}
                  />
                  <FormControlLabel
                    label="Vis bare lukkede prosjekter"
                    control={(
                      <Checkbox
                        checked={showOnlyAssignmentWithExpiredProject}
                        onChange={() => dispatch(setOnlyAssignmentsWithExpiredProject(!showOnlyAssignmentWithExpiredProject))}
                      />
        )}
                  />
                  <FormControlLabel
                    label="Vis utstyr med tildeling overlapp"
                    control={(
                      <Checkbox
                        checked={showOnlyEventOverlap}
                        onChange={() => dispatch(setOnlyEventOverlap(!showOnlyEventOverlap))}
                      />
        )}
                  />
                </Box>
              </span>
            </span>

          </PopMenu>
          <ButtonGroup>
            <Button onClick={() => onCalendarViewChange(view.month)} variant={currentView === 'maned' ? 'contained' : 'outlined'}>Måned</Button>
            <Button onClick={() => onCalendarViewChange(view.quarter)} variant={currentView === 'kvartal' ? 'contained' : 'outlined'}>Kvartal</Button>
            <Button onClick={() => onCalendarViewChange(view.year)} variant={currentView === 'ar' ? 'contained' : 'outlined'}>År</Button>
          </ButtonGroup>
        </Box>
      </Box>

      <Modal
        open={addModalOpen}
        title="Ny utstyrbestilling"
        onClose={() => setAddModalOpen(false)}
      >
        <NewEquipmentOrderModal onClose={() => setAddModalOpen(false)} />
      </Modal>
    </div>
  );
};
